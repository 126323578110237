import intlTelInput from "intl-tel-input";
import { Button } from "../../../components/Button/Button";
import {
  CheckGreenIcon,
  WhatsappIcon,
} from "../../../components/IconStore/IconStore";
import "intl-tel-input/build/css/intlTelInput.css";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import {
  isValidEmailProvider,
  validateEmail,
} from "../../../utils/validateEmail";
import { Event } from "../../../components/Tracking";
import { BrandLogos } from "../../Home/BrandLogos/BrandLogos";
import { Navbar } from "../../../components/Navbar/Navbar";

const BRAND_ICONS = [
  { name: "amazon", path: "/brands/amazon.png" },
  { name: "unilever", path: "/brands/unilever.png" },
  { name: "tencent", path: "/brands/tencent.png" },
  { name: "panasonic", path: "/brands/panasonic.png" },
  { name: "mars", path: "/brands/mars.png" },
  { name: "meesho", path: "/brands/meesho.png" },
  { name: "mamaearth", path: "/brands/mamaearth.png" },
  { name: "pharmeasy", path: "/brands/pharmeasy.png" },
  { name: "zomato", path: "/brands/zomato.png" },
  { name: "plum", path: "/brands/plum.png" },
  { name: "cetaphil", path: "/brands/cetaphil.png" },
  { name: "pinterest", path: "/brands/pinterest.png" },
  { name: "myntra", path: "/brands/myntra.png" },
  { name: "lazypay", path: "/brands/lazypay.png" },
];

export const GetStartedForm = () => {
  const [name, setName] = useState("");
  const [brand, setBrand] = useState("");
  const [email, setEmail] = useState("");
  const [phoneInput, setPhoneInput] = useState("");
  const [budgetAmount, setBudgetAmount] = useState("");
  const [userType, setUserType] = useState("");
  const [moreDetails, setMoreDetails] = useState("");

  useEffect(() => {
    const input = document.querySelector("#mobile");
    const phInput = intlTelInput(input, {
      initialCountry: "in",
      utilsScript:
        "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js",
      // any initialisation options go here
    });
    setPhoneInput(phInput);
  }, []);

  const submit = () => {
    // const errorMap = ['Invalid number', 'Invalid country code', 'Too short', 'Too long', 'Invalid number']
    const phoneNumber = phoneInput.getNumber();
    const countryData = phoneInput.getSelectedCountryData();
    let phPrefix = `+${countryData.dialCode}`;
    let ph = phoneNumber.slice(phPrefix.length);
    // if (!phoneInput.isValidNumber()) {
    //   var errorCode = phoneInput.getValidationError()
    //   alert(errorMap[errorCode])
    //   return
    //   // setPhoneError(errorMap[errorCode])
    // }
    let val = `${phPrefix} ${ph}`;
    if (!name) {
      toast.error("Your name is required");
      return false;
    }
    if (!validateEmail(email)) {
      toast.error("Email is not valid");
      return false;
    }
    if (!isValidEmailProvider(email)) {
      toast.error("Please use your company Email-ID");
      return false;
    }
    if (!phoneInput.isValidNumber()) {
      toast.error("Phone number is not valid");
      return false;
    }
    if (!userType) {
      toast.error("Please select who you are?");
      return false;
    }
    if (!moreDetails) {
      toast.error("Please provide some details about you");
      return false;
    }
    if (!brand) {
      toast.error("Brand name is required");
      return false;
    }
    if (!budgetAmount) {
      toast.error("Budget is required");
      return false;
    }
    const toastId = toast.loading("Submitting...");

    const bodyPayload = {
      name: name,
      email: email,
      phone: val,
      type: userType,
      brand_name: brand,
      budget_amount: Number(budgetAmount),
      deal_description: moreDetails,
    };
    const RESPONSE_MESSAGE = "Thank you! We will be in touch with you shortly!";

    fetch(
      `https://node-ghbwrcsk2-skimpress.vercel.app/api/event?${new URLSearchParams(
        bodyPayload
      ).toString()}`
    )
      .then((res) => {
        return res.json();
      })
      .then(({ success }) => {
        toast.dismiss(toastId);
        if (success) {
          toast.success(RESPONSE_MESSAGE);
          setBrand("");
          setEmail("");
          setName("");
          setUserType("");
          setMoreDetails("");
          setBudgetAmount("");
          document.querySelector("#mobile").value = "";
        } else {
          toast.success(RESPONSE_MESSAGE);
          setBrand("");
          setEmail("");
          setName("");
          setUserType("");
          setMoreDetails("");
          setBudgetAmount("");
          document.querySelector("#mobile").value = "";
        }
        Event("CONTACT FORM", "thank_you_success", "GET_STARTED_PAGE");
      })
      .catch((e) => {
        toast.dismiss(toastId);
        toast.success(RESPONSE_MESSAGE);
        setBrand("");
        setEmail("");
        setName("");
        setUserType("");
        setMoreDetails("");
        setBudgetAmount("");
        document.querySelector("#mobile").value = "";
        Event("CONTACT FORM", "thank_you_success", "GET_STARTED_PAGE");
      });
  };

  const handleWhatsappClickEvent = () => {
    Event(
      "GET STARTED CONTACT FORM",
      "whatsapp_button_clicked",
      "GET_STARTED_PAGE"
    );
  };

  const nameTitle =
    userType === "brand"
      ? "Brand"
      : userType === "creator"
      ? "Creator"
      : "Agency";

  return (
    <div className="">
      <div className="flex lg:flex-row flex-col">
        <div className="sm:pl-24 md:w-1/2 w-full items-center pr-8">
          <Navbar />
          <div className="pl-5">
            <div className="font-sans text-4xl sm:text-6xl lg:leading-17 font-bold text-gradient bg-gradient-to-r from-oi-teal-300 to-oi-purple-300 tracking-wider mt-8">
              Get an influencer campaign proposal in 24 hrs
            </div>
            <div className="mt-9">
              <div className="flex items-center mt-2 text-xl">
                <CheckGreenIcon />{" "}
                <span className="ml-4 sm:ml-3.5 text-sm sm:text-xl ">
                  Get started with a min. budget of Rs. 25,000 per campaign
                </span>
              </div>
              <div className="flex items-center mt-2 text-xl">
                <CheckGreenIcon />{" "}
                <span className="ml-4 sm:ml-3.5 text-sm sm:text-xl">
                  Discover over 6.5 Mn influencers across Instagram & Youtube
                </span>
              </div>
              <div className="flex items-center mt-2 text-xl">
                <CheckGreenIcon />{" "}
                <span className="ml-4 sm:ml-3.5 text-sm sm:text-xl">
                  Faster, reliable & scalable campaigns with end to end
                  management
                </span>
              </div>
              <div className="flex items-center mt-2 text-xl">
                <CheckGreenIcon />{" "}
                <span className="ml-4 sm:ml-3.5 text-sm sm:text-xl">
                  Maximize ROI with best prices in the industry
                </span>
              </div>
            </div>
            <BrandLogos page="get-started" brandIconArray={BRAND_ICONS} />
          </div>
        </div>
        <div
          className="flex-1 w-full lg:mt-0 mt-10 p-4 sm:p-24 sm:pl-20 sm:pt-36 pt-14"
          style={{
            background: `linear-gradient(110.43deg, #30C0B7 11.5%, #706DF9 97.84%)`,
          }}
        >
          <div className="w-full bg-white overflow-hidden rounded-3xl contact-form-box-shadow pt-4">
            <div className="px-5 flex items-center border-b border-oi-gray-200">
              <span className="min-w-min w-1/3">Your Name</span>
              <div className=" ml-auto w-2/3">
                <input
                  type="text"
                  className="p-7 w-full focus:outline-none"
                  placeholder="Enter Name"
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
            </div>
            <div className="px-5 flex items-center border-b border-oi-gray-200">
              <span className="min-w-min w-1/3">Mobile No.</span>
              <div className="ml-auto w-2/3">
                <div className="relative mt-1 rounded-md shadow-sm">
                  <input
                    type="tel"
                    name="mobile"
                    id="mobile"
                    className="p-7 w-full focus:outline-none ml-16"
                    placeholder="Enter your number"
                  />
                </div>
              </div>
            </div>
            <div className="px-5 flex items-center border-b border-oi-gray-200">
              <span className="min-w-min w-1/3">Your Company Email</span>
              <div className=" ml-auto w-2/3">
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  className="p-7 w-full focus:outline-none"
                  placeholder="e.g jamessmith@company.com"
                />
              </div>
            </div>
            <div className="px-5 flex items-center border-b border-oi-gray-200">
              <span className="min-w-min w-1/3">You are:</span>
              <div className="ml-auto w-2/3">
                <select
                  onChange={(e) => setUserType(e.target.value)}
                  value={userType}
                  required
                  className="p-7 w-full focus:outline-none"
                  style={{ backgroundColor: "#ffffff" }}
                >
                  <option value="">Select</option>
                  <option value="brand">Brand</option>
                  <option value="agency">Agency</option>
                  <option value="creator">Creator</option>
                </select>
              </div>
            </div>
            <div className="px-5 flex items-center border-b border-oi-gray-200">
              <span className="min-w-min w-1/3">{nameTitle} Name</span>
              <div className=" ml-auto w-2/3">
                <input
                  type="text"
                  value={brand}
                  onChange={(e) => setBrand(e.target.value)}
                  className="p-7 w-full focus:outline-none"
                  placeholder="e.g Zara India"
                />
              </div>
            </div>
            <div className="px-5 flex items-center border-b border-oi-gray-200">
              <span className="min-w-min w-1/3">
                Provide {userType} details
              </span>
              <div className=" ml-auto w-2/3">
                <input
                  type="text"
                  value={moreDetails}
                  onChange={(e) => setMoreDetails(e.target.value)}
                  required
                  className="p-7 w-full focus:outline-none"
                  placeholder={`eg. enter ${userType} details here`}
                />
              </div>
            </div>

            <div className="px-5 flex items-center border-b border-oi-gray-200">
              <span className="min-w-min w-1/3">Budget Option</span>
              <div className="ml-auto w-2/3">
                <select
                  onChange={(e) => setBudgetAmount(e.target.value)}
                  value={budgetAmount}
                  required
                  className="p-7 w-full focus:outline-none"
                  style={{ backgroundColor: "#ffffff" }}
                >
                  <option value="">Select Budget</option>
                  <option value="500000">2 Lacs - 5 Lacs</option>
                  <option value="2000000">10 Lacs - 20 Lacs</option>
                  <option value="5000000">20 Lacs - 50 Lacs</option>
                  <option value="10000000">50 Lacs - 1 Cr</option>
                  <option value="50000000">1 Cr - 5 Cr</option>
                  <option value="50000001">5 Cr+</option>
                </select>
              </div>
            </div>
            <div className="w-full flex justify-between px-5 py-7">
              <div className="w-2/3 mr-2 flex">
                <a
                  href="https://wa.me/919311124148"
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => handleWhatsappClickEvent()}
                >
                  <Button
                    type="green"
                    size="small"
                    text="Or Message on WhatsApp"
                    rightIcon={
                      <WhatsappIcon className="p-0 ml-1 h-3 w-3 sm:ml-4 sm:h-6 sm:w-6" />
                    }
                    className="w-full rounded-lg text-xs sm:text-base"
                  />
                </a>
              </div>
              <div className="w-1/3 flex">
                <Button
                  text="Submit"
                  size="small"
                  onClick={submit}
                  className="w-full rounded-lg text-xs sm:text-base"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
