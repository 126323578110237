export const Button = ({
  onClick,
  type = "filled",
  className = "",
  text = "",
  rightIcon,
  leftIcon,
  size = "medium",
  btnType = "button",
  style,
  disabled,
}) => {
  let outlineClass =
    type === "outline" ? "text-oi-teal-400 border border-oi-teal-400" : "";
  switch (type) {
    case "outline":
      outlineClass = "text-oi-teal-400 border border-oi-teal-400";
      break;
    case "filled":
      outlineClass = "text-white bg-oi-teal-400";
      break;
    case "green":
      outlineClass = "text-white bg-oi-green-300";
      break;
    case "white":
      outlineClass = "text-black bg-oi-white-300";
      break;
    default:
      outlineClass = "text-white bg-oi-teal-400";
      break;
  }
  let SizeClass = "py-2.5";
  switch (size) {
    case "small":
      break;
    case "medium":
      SizeClass = "py-2.5";
      break;
    case "large":
      SizeClass = "py-4";
      break;
    default:
      SizeClass = "py-2.5";
  }
  return (
    <button
      onClick={onClick}
      type={btnType}
      className={`rounded inline-flex font-bold  justify-center items-center px-6 ${SizeClass} ${outlineClass} ${className}`}
      style={style}
      disabled={disabled}
    >
      {leftIcon}
      <span>{text}</span>
      {rightIcon}
    </button>
  );
};
