import React from "react";

//Components
import { TermsAndConditionsLayout } from "../../layouts/TermsAndConditions";

const guidelines = [
  {
    serialNo: 1,
    value: `<strong>Verify Communication Sources:</strong> Always authenticate communication purportedly from our organization. Utilize only the verified contact information provided to you (mail address with ‘oneimpression.io’ domain). If you are added to a suspicious whatsapp/telegram or such other channel please reach out to One Impression’s SPOC or verify the authenticity of the group by writing to us. If you receive any communication raising doubts about its legitimacy, please contact us directly through official channels to confirm its authenticity.`,
  },
  {
    serialNo: 2,
    value: `<strong>Use Verified Payment Methods:</strong> Ensure payments related to our services are made using the verified methods provided by us. One Impression exclusively processes payments via NEFT in its account named ‘Anycast Technology Private Limited’. Avoid sharing sensitive financial information over unsecured channels or to unknown parties. If you receive unusual payment instructions, verify them directly with us before proceeding. We do not deal in payments via UPI.`,
  },
  {
    serialNo: 3,
    value: `<strong>Stay Informed and Educated:</strong> Keep abreast of the latest security best practices and remain vigilant against phishing attempts or fraudulent activities. Educate yourself and your team on recognizing and responding to potential threats effectively. Regularly review security protocols and ensure compliance with established procedures.`,
  },
  {
    serialNo: 4,
    value: `<strong>Report Suspicious Activity:</strong> Promptly notify us of any suspicious activity or communication that raises security concerns. Your swift reporting enables us to investigate and address potential risks promptly.`,
  },
];

export const SecurityAnnouncement = () => {
  return (
    <TermsAndConditionsLayout>
      <div className="mx-auto mt-8 lg:mt-16 px-5 max-w-lg sm:max-w-screen-sm md:max-w-screen-md lg:max-w-screen-xl">
        <div className="text-2xl not-italic text-center font-bold font-serif text-oi-gray-900 tracking-wider mb-5">
          Important Security Notice: Safeguarding Your Transactions and
          Communications
        </div>
        <div className="mb-5 flex justify-center">
          <div className="text-xs flex flex-col w-4/5 not-italic font-normal font-serif text-black mb-2">
            Dear Valued Clients,
            <br /> <br /> At One Impression, safeguarding your interests and
            ensuring the security of your transactions and communications is our
            top priority. Unfortunately, we have become aware of scams and
            fraudulent activities where certain unauthorized individuals are
            impersonating our company or acting as our agents to deceive our
            clients.
            <br />
            <br /> To protect against these risks, we urge you to adhere to the
            following guidelines:
            {guidelines.map(({ serialNo, value }) => (
              <div
                className="flex flex-row mt-5"
                key={`${serialNo}_security_announcement`}
              >
                <div>{serialNo}.</div>
                <div
                  className="ml-5"
                  dangerouslySetInnerHTML={{ __html: value }}
                />
              </div>
            ))}
            <br />
            We are committed to maintaining the integrity and trustworthiness of
            our business interactions. Together, by remaining vigilant and
            working collaboratively, we can mitigate the risks posed by
            fraudulent activities and uphold the security of our transactions.
            <br />
            <br />
            Thank you for your cooperation and continued trust in One
            Impression.
          </div>
        </div>
      </div>
    </TermsAndConditionsLayout>
  );
};
