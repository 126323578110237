export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const isValidEmailProvider = (email) => {
  const invalidEmailProvider = [
    "gmail",
    "hotmail",
    "yahoo",
    "gamil",
    "company",
    "yaho",
    "yahhoo",
    "rediffmail",
    "rediff",
    "redif",
    "redifmail",
    "yahooo",
  ];
  const emailProviderNameAfterAtTheRateSymbol = email
    ?.split("@")[1]
    ?.split(".")[0]
    ?.toLowerCase();
  return !invalidEmailProvider?.includes(emailProviderNameAfterAtTheRateSymbol);
};
