import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { hiringURL } from "../../utils/commonUtils";

export const Navbar = ({ onContactSalesClick }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const location = useLocation();

  const handleContactSalesClick = () => {
    setIsMenuOpen(false);
    onContactSalesClick();
  };

  return (
    <>
      <div
        className="xl:hidden w-96 h-full fixed right-0 bg-white"
        style={{
          transition: "all .8s ease",
          transform: `translateX(${isMenuOpen ? 0 : "100%"})`,
          zIndex: "99",
          top: "0",
        }}
      >
        <div style={{ paddingLeft: "350px", paddingTop: "20px" }}>
          <img
            src="xmark-solid.svg"
            className="w-3 cursor-pointer "
            onClick={() => setIsMenuOpen(false)}
          />
        </div>
        {location.pathname !== "/get-started" && (
          <>
            <div className="flex items-center py-1">
              <a className="flex pl-6" href={hiringURL} target="_blank">
                We're hiring
                <img
                  src="hiring.png"
                  alt="hiring"
                  className="h-4 w-4 -ml-1 -mt-0.5"
                />
              </a>
            </div>
            <div className="flex items-center py-1">
              <a
                className="pl-6 "
                href="https://docs.google.com/forms/d/e/1FAIpQLScJm-gxPRURNWN_3pb9bdTaAWS17JfsqJ2acKnAyCxEtffFwg/viewform"
                target="_blank"
              >
                Sign up as creator
              </a>
            </div>
            {
              //   <div className="flex items-center py-1">
              //   <a className="pl-6">Pricing</a>
              // </div>
            }
            <div className="flex items-center py-1">
              <a
                className="pl-6"
                href="https://dashboard.oneimpression.io/"
                target="_blank"
              >
                Login
              </a>
            </div>
            <div className="py-1">
              <span
                className="w-40 pl-6 text-green-500 cursor-pointer"
                onClick={handleContactSalesClick}
              >
                Contact Sales
              </span>
            </div>
          </>
        )}
      </div>
      <nav>
        <div className="max-w-screen-xl mx-auto py-9 px-5">
          <div className="flex justify-between items-center">
            <Link className="w-218 xl:w-218" to="/">
              <img src="one-logo.png" alt="oneimpression" />
            </Link>
            {location.pathname !== "/get-started" && (
              <>
                <img
                  src="hamburger.png"
                  className="xl:hidden cursor-pointer"
                  onClick={() => setIsMenuOpen(true)}
                />
                <div className="ml-auto xl:flex hidden">
                  <div className="flex items-center py-1">
                    <a className="flex pl-6" href={hiringURL} target="_blank">
                      We're hiring
                      <img
                        src="hiring.png"
                        alt="hiring"
                        className="h-4 w-4 -ml-1 -mt-0.5"
                      />
                    </a>
                  </div>
                  <div className="flex items-center py-1">
                    <a
                      className="pl-6 "
                      href="https://docs.google.com/forms/d/e/1FAIpQLScJm-gxPRURNWN_3pb9bdTaAWS17JfsqJ2acKnAyCxEtffFwg/viewform"
                      target="_blank"
                    >
                      Sign up as creator
                    </a>
                  </div>
                  {
                    //   <div className="flex items-center py-1">
                    //   <a className="pl-6">Pricing</a>
                    // </div>
                  }
                  <div className="flex items-center py-1">
                    <a
                      className="pl-6"
                      href="https://dashboard.oneimpression.io/"
                      target="_blank"
                    >
                      Login
                    </a>
                  </div>
                  <span
                    className="flex items-center ml-6 bg-oi-teal-400 py-2 px-5 text-oi-white-300 rounded-md cursor-pointer"
                    onClick={onContactSalesClick}
                  >
                    Contact Sales
                  </span>
                </div>
              </>
            )}
          </div>
        </div>
      </nav>
    </>
  );
};
