import { useEffect } from "react";
import { Toaster } from "react-hot-toast";
import { Routes, Route } from "react-router-dom";

// COMPONENTS
import { GetStarted } from "./pages/GetStarted/GetStarted";
import { Home } from "./pages/Home/Home";
import { Legal } from "./pages/Legal/Legal";
import { InfluencerManagementContract } from "./pages/InfluencerManagementContract/InfluencerManagementContract";
import { TermsOfService } from "./pages/TermsOfService";
import { PrivacyPolicyPage } from "./pages/PrivacyPolicyPage";
import { initGA, PageView } from "./components/Tracking";
import { BrandTermsAndConditions } from "./pages/TermsOfService/BrandTermsAndConditions";
import { InfluencerTermsAndCondition } from "./pages/TermsOfService/InfluencerTermsAndCondition";
import { SecurityAnnouncement } from "./pages/SecurityAnnouncement/SecurityAnnouncement";

// CSS
import "./App.css";
import "./styles/styles.css";
import Pricing from "./pages/Pricing";

function App() {
  useEffect(() => {
    initGA("G-3E0HJH7L02");
    PageView();
  }, []);
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/get-started" element={<GetStarted />} />
        <Route path="/legal" element={<Legal />} />
        <Route
          path="/influencer-management-contract"
          element={<InfluencerManagementContract />}
        />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route
          path="/brand-terms-and-conditions"
          element={<BrandTermsAndConditions />}
        />
        <Route
          path="/influencer-terms-and-conditions"
          element={<InfluencerTermsAndCondition />}
        />
        <Route
          path="/security-announcement"
          element={<SecurityAnnouncement />}
        />
      </Routes>
      <div>
        <Toaster />
      </div>
    </div>
  );
}

export default App;
