import { Fragment, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Dialog, Transition } from "@headlessui/react";
import { CloseFilledIcon, PhoneIcon } from "../IconStore/IconStore";
import intlTelInput from "intl-tel-input";
import toast from "react-hot-toast";
import { Link, Space } from "../PrivacyPageComponents";
import { isValidEmailProvider, validateEmail } from "../../utils/validateEmail";

const color = "rgba(48, 184, 192)";
const styles = ` 
  .input {
    width: 100%;
    display: flex;
  }

  .input .iti__country-list {
    position: absolute;
    background: white;
    height: 200px;
    overflow: auto;
    z-index: 1;
    border-radius: 8px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
    top: 28px;
    width: 100%;
  }

  .input #popup-mobile {
    padding-left: 50px
  }

  .input .hs-input , .iti--allow-dropdown {
    width: 100% !important;
  }

  .iti--container {
    top: 20% !important;
    background-color: inherit !important;
    height: 200px !important;
  }

  .iti__dropdown-content {
    box-shadow:0 0 32px 0 rgba(0,0,0,0.5)
  }

  .input .iti__flag-container {
    position: absolute;
    width: 46px;
    height: 40px;
  }

  .input .iti__selected-flag {
    position: absolute;
    top: 12px;
    left: 10px;
  }

  .input select {
    /* Hide the default arrow */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .input .iti__arrow {
    position: absolute;
    top: 18px;
    left: 28px;
  }

  .input .iti__flag-container:hover {
    background-color: #80808047;
    cursor: pointer;
  }

    .paddingTopEight {
      padding-top: 8px !important;
    }

    label {
      margin: 0;
    }

    .input input , select {
      border: 1px solid rgba(229, 231, 235);
      border-radius: 4px !important;
      background-color: white !important;
    }

    #privacy-policy div {
      font-family: ProximaNovaSoftW03-Regular, sans-serif;
      font-size: 14px !important;
      line-height: 24px !important;
    }

    #privacy-policy a {
      color: rgba(48, 192, 183);
      text-decoration: none;
    }
  
    #privacy-policy #checkbox {
      position: absolute;
      top: 2px;
    }
    .hs-form-field {
      display: flex;
      align-items: center;
    }

    .hs-form-field label {
        width: 30%;
    }

    .hs-submit {
      .hs-button {
        background-color: ${color};
        border-radius: 8px;
        border-color: ${color};
      }

      .hs-button:hover {
        background-color: ${color} !important;
        border-color: ${color} !important;
      }
    }

    .hs-form-required , .hs-error-msgs  {
      display: none;
    }

    .font-regular {
      font-family: ProximaNovaSoftW03-Regular, sans-serif !important;
    }
`;

const internationalInputPackage = `
  <style type='text/css'>${styles}</style>
  <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/intl-tel-input@22.0.2/build/css/intlTelInput.css">
`;

export const ScheduleDemo = ({ isOpen = false, setOpen = () => null }) => {
  const [iframeInitialized, setIframeInitialized] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
  }, [isOpen, iframeInitialized]);

  useEffect(() => {
    if (isOpen) {
      createHubSpotForm();
    } else {
      setIframeInitialized(false);
    }
  }, [isOpen]);

  const onFormReady = () => {
    const iframes = document.getElementsByClassName("hs-form-iframe");
    const [iframe] = iframes || [];
    if (!iframe) return;
    setIframeInitialized(true);

    const iframeDocument = iframe.contentDocument;
    if (!iframeDocument) return;
    const spansFields = Array.from(iframeDocument.querySelectorAll("span"));
    let inputFields = Array.from(iframeDocument.querySelectorAll(".hs-input"));
    const submitContainer = iframeDocument.querySelector(".hs_submit");
    const finalSubmitButton = iframeDocument.querySelector(".hs-button");

    if (!submitContainer || !finalSubmitButton || inputFields.length < 5)
      return;

    iframeDocument.head.insertAdjacentHTML(
      "beforeend",
      internationalInputPackage
    );

    const scriptEl = iframeDocument.createElement("script");
    scriptEl.id = "intlScript";
    scriptEl.src =
      "https://cdn.jsdelivr.net/npm/intl-tel-input@22.0.2/build/js/intlTelInput.min.js";
    iframeDocument.head.appendChild(scriptEl);
    const initializingScript = iframeDocument.createElement("script");
    initializingScript.innerHTML = `
      const scriptEl=document.getElementById('intlScript')
      scriptEl.onload=()=>{
        const inputFields = Array.from(document.querySelectorAll(".hs-input"));
        const input = inputFields[2];
        window.intlTelInput(input, {
          utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@22.0.2/build/js/utils.js",
          initialCountry: "in",
        });
      }
    `;
    iframeDocument.head.appendChild(initializingScript);

    iframeDocument.body.addEventListener("click", (e) => {
      const countryList = iframeDocument.querySelector(".iti__country-list");
      const countryArrow = iframeDocument.querySelector(".iti__arrow");
      if (!countryArrow || !countryList) return;
      countryList.style.display = "block";
      if (
        iframeDocument.querySelector(".iti--allow-dropdown")?.contains(e.target)
      ) {
        countryArrow.classList.add("iti__arrow--up");
      } else {
        countryList.style.display = "none";
        countryArrow.classList.remove("iti__arrow--up");
      }
    });

    spansFields.forEach((element) => {
      element.classList.add("font-regular");
    });

    inputFields[0].placeholder = "Enter your name";
    inputFields[1].placeholder = "Enter your email";

    const phoneInputField = inputFields[2];
    phoneInputField.placeholder = "Enter your phone number";
    phoneInputField.setAttribute("id", "popup-mobile");
    phoneInputField.addEventListener("input", (event) => {
      const value = event.target.value;
      event.target.value = value.replace(/\D/g, "");
    });

    const check = (
      <div style={{ display: "flex", marginTop: "48px", position: "relative" }}>
        <input type="checkbox" id="checkbox" className="h-6 w-6 rounded-lg" />
        <div style={{ marginLeft: "40px", fontSize: "16px" }}>
          By proceeding, you hereby agree that you have read, acknowledged,
          understood, accepted and consented to the website’s
          <Space />
          <Link
            text="Privacy Policy"
            href="https://www.oneimpression.io/privacy-policy"
            className="privacyPolicy"
          />
          <Space />
          and
          <Space />
          <Link
            text="Terms of Service"
            href="https://www.oneimpression.io/terms-of-service"
            className="privacyPolicy"
          />
          .
        </div>
      </div>
    );

    const tempContainer = document.createElement("div");
    tempContainer.id = "privacy-policy";

    submitContainer.parentNode.insertBefore(tempContainer, submitContainer);

    ReactDOM.render(check, iframeDocument.querySelector("#privacy-policy"));

    const checkbox = iframeDocument
      .querySelector("#privacy-policy")
      ?.querySelector("#checkbox");

    finalSubmitButton.defaultValue = "Request Callback";

    function checkInputsFilled() {
      const result = inputFields.every((input) => input.value.trim() !== "");
      const email = inputFields[1].value;
      const isEmailValid = validateEmail(email) && isValidEmailProvider(email);
      return result && checkbox.classList.contains("checked") && isEmailValid;
    }

    function updateSubmitButtonStatus() {
      if (checkInputsFilled()) {
        finalSubmitButton.removeAttribute("disabled");
        finalSubmitButton.style.opacity = 1;
      } else {
        finalSubmitButton.setAttribute("disabled", "disabled");
        finalSubmitButton.style.opacity = 0.5;
      }
    }

    finalSubmitButton.addEventListener("click", () => {
      setLoading(true);
      setTimeout(() => {
        toast.success(
          iframeDocument.querySelector(".submitted-message")?.innerText ||
            "Thanks, Our team will be in touch shortly."
        );
        setOpen(false);
      }, [1000]);
    });
    updateSubmitButtonStatus();

    inputFields.forEach((input) => {
      input.addEventListener("input", updateSubmitButtonStatus);
    });

    const selectField = inputFields[3];
    selectField.classList.add("paddingTopEight");

    selectField.addEventListener("change", () => {
      setTimeout(() => {
        if (inputFields.length >= 6) {
          inputFields = Array.from(
            iframeDocument.querySelectorAll(".hs-input")
          );
          inputFields[4].addEventListener("input", updateSubmitButtonStatus);
          inputFields[5].addEventListener("input", updateSubmitButtonStatus);
          updateSubmitButtonStatus();
        }
      }, [200]);
    });

    checkbox.addEventListener("click", () => {
      checkbox.classList.toggle("checked");
      updateSubmitButtonStatus();
    });

    setLoading(false);
  };

  const handleScriptCallback = async () => {
    if (window.hbspt) {
      window.hbspt.forms.create({
        region: "na1",
        portalId: "7218828",
        formId: "a656b7a0-9af8-4f5f-a61f-89844f376185",
        target: "#scheduleForm",
        onReady: onFormReady,
      });
    }
  };
  const createHubSpotForm = () => {
    if (iframeInitialized) {
      return;
    }

    const script = document.createElement("script");
    script.src = "//js.hsforms.net/forms/embed/v2.js";
    script.type = "text/javascript";
    script.async = true;
    document.body.appendChild(script);
    script.onload = handleScriptCallback;
  };

  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={setOpen}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-oi-gray-900 opacity-50" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div
              className="inline-block relative w-full max-w-2xl px-8 pt-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl"
              style={{ opacity: loading ? 0 : 1 }}
            >
              <div className="absolute top-4 right-4" onClick={setOpen}>
                <CloseFilledIcon />
              </div>

              <div className="mt-3">
                <PhoneIcon />
                <h2 className="text-gray-900 text-2xl mt-5 font-bold">
                  Schedule demo
                </h2>
              </div>
              <div className="mt-2 mb-12">
                <p className="text-xl text-gray-900 font-normal">
                  Get a campaign proposal within 24 hours
                </p>
              </div>
              <div id="scheduleForm" className="font-normal" />
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};
