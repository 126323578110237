import React, { Fragment } from "react";
import { CloseFilledIcon } from "../IconStore/IconStore";
import { Transition } from "@headlessui/react";
import { Button } from "../Button/Button";
import { Link, Space } from "../PrivacyPageComponents";

const Cookies = ({ onClick = () => {}, closeIconClick, show = false }) => {
  return (
    <Transition
      show={show}
      as={Fragment}
      leave="transition-opacity duration-1000"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="fixed bg-white right-10 bottom-10 cookieContainer flex-col transition-all transform">
        <div className="flex justify-between">
          <span className="text-xl font-normal gilroy-bold">
            Privacy Preferences
          </span>
          <CloseFilledIcon
            className="cursor-pointer"
            backgroundColor="#1D252D94"
            onClick={closeIconClick}
          />
        </div>
        <div className="mt-2 font-normal text-sm to-oi-gray-900 gilroy-medium">
          We use cookies and other tracking technologies to improve your
          browsing experience on our website, to analyze our website traffic,
          and to understand where our visitors are coming from. For more
          information on how we use cookies, please visit our
          <Space />
          <Link
            text="Privacy Policy"
            href="/privacy-policy"
            className="no-underline text-oi-teal-300"
          />
          .
        </div>
        <div className="mt-2 font-normal text-sm gilroy-medium">
          By clicking ‘I Accept’ on this banner or browsing our website, you
          consent to the use of cookies, unless you have disabled them.
        </div>
        <div className="mt-5">
          <Button text="I Accept" className="rounded-md" onClick={onClick} />
        </div>
      </div>
    </Transition>
  );
};

export default Cookies;
