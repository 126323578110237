import React, { useEffect } from "react";
import { Navbar } from "../components/Navbar/Navbar";
import { Footer } from "../components/Footer/Footer";
import { useState } from "react";
import { ScheduleDemo } from "../components/Modal/ScheduleDemo";
import Cookies from "../components/Cookies";

export const MainLayout = ({ children }) => {
  const [isScheduleDemoOpen, setIsScheduleDemoOpen] = useState(false);
  const [showCookiePopup, setShowCookiePopup] = useState();

  const handleIAccept = () => {
    localStorage.setItem("accept-cookie", "true");
    setShowCookiePopup(false);
  };
  const handleCloseIcon = () => {
    setShowCookiePopup(false);
  };

  useEffect(() => {
    const cookieValue = localStorage.getItem("accept-cookie");
    if (cookieValue !== "true") setShowCookiePopup(true);
  }, []);

  return (
    <div className="relative overflow-hidden">
      <div className="flex justify-center items-center topBadgeContainer pt-3 pb-3">
        <img
          src="rocket.svg"
          alt="rocket"
          className="sm:mr-2 sm:w-3xl sm:h-3xl w-5 h-5 sm:w-7 sm:h-7 ml-3"
        />
        <div className="sm:font-normal sm:text-base text-xs text-oi-white-300 ml-4 mr-2">
          We are excited to announce that we have secured our Series A financing
          as part of a $10 Million round led by Krafton.
          <a
            href="https://techcrunch.com/2023/03/14/one-impression-krafton-india-funding/"
            target="_blank"
            className="pl-1 sm:pl-1 sm:font-normal sm:text-base text-xs text-oi-teal-400 cursor-pointer mr-3"
          >
            Read More
          </a>
        </div>
      </div>
      <Navbar
        onContactSalesClick={() => setIsScheduleDemoOpen(!isScheduleDemoOpen)}
      />
      {children}
      <Footer
        onTalkToUsClick={() => setIsScheduleDemoOpen(!isScheduleDemoOpen)}
      />
      <ScheduleDemo
        isOpen={isScheduleDemoOpen}
        setOpen={() => setIsScheduleDemoOpen(!isScheduleDemoOpen)}
      />
      <Cookies
        onClick={handleIAccept}
        closeIconClick={handleCloseIcon}
        show={showCookiePopup}
      />
    </div>
  );
};
