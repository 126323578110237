import React from "react";

// COMPONENT
import { TermsAndConditionsLayout } from "../../../layouts/TermsAndConditions";

const conditions = [
  {
    serial_no: "a",
    value: `No Endorsement: The Company does not endorse any individual Influencer, except such Influencers who have signed up for the One Club program.`,
  },
  {
    serial_no: "b",
    value: `No Employment: The Influencers are not employees of the Company, they are Users who have registered on the Platform to participate in Campaigns. Any claims that you wish to initiate or legal remedy you wish to avail against the Influencer will be limited to a claim against the particular Influencer or other third parties who caused harm to the Brand, and the Brand agrees not to attempt to impose liability on the Company or seek any legal remedy from the Company with respect to such actions or omissions.`,
  },
  {
    serial_no: "c",
    value: `Campaigns: The Brand represents and warrants that Brand owns all of the content shared in the Campaign or that the Brand has all rights necessary to grant the Company the license rights to all of the content shared in the Campaign under the Terms and Conditions. Brand also represents and warrants that neither the content shared in the Campaign, nor Company’s or Influencer’s use and provision of the content shared in the Campaign, will infringe, misappropriate or violate a third party's intellectual property rights, or rights of publicity or privacy, or result in the violation of any applicable law or regulation, including export control laws. Once an Influencer has been confirmed, the Brand cannot revise or modify the Campaign details and Campaign Deliverable expectations save and except if the Influencer accepts such revision. In case of any revision in the Campaign additional fees may be charged based on the extent of the revision/change/deviation from the original Campaign.`,
  },
  {
    serial_no: "d",
    value: `Quotes: The Company will share the quotes depicting the commercials for the creating the Campaign Deliverables. If specifically requested by the Brand, the Company will negotiate the Quote on the Brand’s behalf with the Influencer.`,
  },
  {
    serial_no: "e",
    value: `Selection of Influencer: Once the Quotes for a particular Influencer are shared they must be confirmed by the Brand in order for the Influencer to be selected.`,
  },
  {
    serial_no: "f",
    value: `Relationship between the Influencer and the Brand: The Brand must at all times transact in good faith with the Influencer. Any concerns or issues pertaining to any specific Influencer will first be notified to the Company. The Brand will endeavour to cordially resolve any issues and refrain from taking any legal recourse without an attempt for amicable resolution.`,
  },
  {
    serial_no: "g",
    value: `Brand shall not require the Influencer to make any claims relating to the quality, efficacy or any other quantitative or qualitative aspect of the Campaign Subject Matter that has not been verified or is true, accurate and can be proven with sufficient evidence.`,
  },
  {
    serial_no: "h",
    value: `The Brand represents that it has documents to support the claims that the Influencer is required to make in the Campaign Deliverable with respect to the Campaign Subject Matter and the Brand shall share the same with the Influencer at the time of sharing details regarding the Campaign. The Company and the Influencer are relying on this representation made by the Brand and shall not be liable for any claims arising in this regard.`,
  },
  {
    serial_no: "i",
    value: `You shall ensure the delivery of Campaign Subject Matter, items, props to the Influencer at the address set out in the email confirmations. It is your responsibility to ensure the Campaign Subject Matter is delivered to the Brand at your own expense.`,
  },
  {
    serial_no: "j",
    value: `The Brand while providing /approving content, using or exploiting the Campaign Materials shall adhere to all the applicable advertising laws including but not limited to Advertising Standard Council of India rules, guidelines in existence or which may come into existence in future relating to digital marketing. The Brand shall solely be liable for any claims arising in this regard. The Brand shall disclose all material information and provide the appropriate labels that the Influencer has to use in the Campaign Material for complying with applicable guidelines including but not limited to ASCI guidelines for digital/influencer marketing or such other guidelines and laws applicable within the jurisdiction where the Campaign has to be released.`,
  },
  {
    serial_no: "k",
    value: `In case of any issue/claim raised in the Campaign Deliverable (which was duly approved and/or provided by the Brand) for the purpose of advertising, promotions, marketing in the Campaign then the Brand shall at its own cost, expense and risk and responsibility promptly defend, pay and settle all such third-party claims and actions including claims raised by ASCI and or any governing and/or non-governing bodies in such manner as Brand may deem fit without any liability on the Influencer.`,
  },
  {
    serial_no: "l",
    value: `The Brand will strive to be as clear and explicit about the Campaign and the Campaign Deliverables while communicating with the Influencer and while creating a Campaign description. The Company and the Influencer will not be liable in the event the Campaign Deliverables do not reflect the Brand’s requirement if there is an error or miscommunication from the Brand with respect to the expected Campaign Deliverables.`,
  },
  {
    serial_no: "m",
    value: (
      <div className="flex flex-col">
        <div>Fees and Payments:</div>
        <div className="mt-5">
          As soon as the Campaign is confirmed the Company shall issue an
          estimate stating the commercials, payment terms such as days of credit
          or advance terms etc and the Brand shall confirm the same over an
          email or on the Platform. The payment will be made as per the terms
          stated in such confirmation email. The Brand shall ensure that
          payments are made on time without any undue delay. No payments will be
          made by the Brand in cash.
        </div>
      </div>
    ),
  },
  {
    serial_no: "n",
    value: `Campaign Cancellation: The Campaign cannot be cancelled once it has been confirmed by the Influencer and the Brand.
    `,
  },
];

export const BrandTermsAndConditions = () => {
  return (
    <TermsAndConditionsLayout>
      <div className="mx-auto mt-8 lg:mt-16 px-5 max-w-lg sm:max-w-screen-sm md:max-w-screen-md lg:max-w-screen-xl">
        <div className="text-2xl not-italic text-center font-bold font-serif text-oi-gray-900 tracking-wider mb-5">
          BRAND TERMS AND CONDITIONS
        </div>
        <div className="mb-5 flex justify-center">
          <div className="text-xs flex flex-col w-4/5 not-italic font-normal font-serif text-black mb-2">
            {conditions.map(({ serial_no, value }, index) => (
              <div
                className="flex flex-row mt-5"
                key={`${index}_${serial_no}_brandTandC`}
              >
                <div>{serial_no}.</div>
                <div className="ml-5">{value}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </TermsAndConditionsLayout>
  );
};
