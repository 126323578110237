import { useState } from "react";
import { Button } from "../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import { ScheduleDemo } from "../../components/Modal/ScheduleDemo";
import { hiringURL } from "../../utils/commonUtils";

export const Footer = ({ onTalkToUsClick = () => null }) => {
  const navigate = useNavigate();
  const [isScheduleDemoOpen, setIsScheduleDemoOpen] = useState(false);
  const currentYear = new Date().getFullYear();

  return (
    <div
      className="pt-8 sm:pt-20 pb-14 sm:pb-24 px-4 sm:px-52"
      style={{ backgroundColor: "#1D252D" }}
    >
      <footer className="max-w-screen-xl mx-auto">
        <div
          style={{ color: "#FFFFFF" }}
          class="text-left sm:text-center font-bold text-4xl sm:text-7xl"
        >
          Start a Campaign Now
        </div>
        <div class="sm:flex mt-5 sm:mt-10 mb-9 sm:mb-16 sm:justify-center">
          <div class="flex sm:mr-16 mb-3 sm:mb-0">
            <img src="check.png" class="h-4 sm:h-6" />
            <div class="text-white font-normal text-sm sm:text-xl ml-3">
              Lightning Fast Speed
            </div>
          </div>
          <div class="flex sm:mr-16 mb-3 sm:mb-0">
            <img src="check.png" class="h-4 sm:h-6" />
            <div class="text-white font-normal text-sm sm:text-xl ml-3">
              Best Creator Pricing
            </div>
          </div>
          <div class="flex sm:mr-16 mb-3 sm:mb-0">
            <img src="check.png" class="h-4 sm:h-6" />
            <div class="text-white font-normal text-sm sm:text-xl ml-3">
              Seamless Delivery
            </div>
          </div>
          <div class="flex">
            <img src="check.png" class="h-4 sm:h-6" />
            <div class="text-white font-normal text-sm sm:text-xl ml-3">
              Realtime Analytics
            </div>
          </div>
        </div>
        <div class="text-left sm:text-center sm:mb-24 mb-16">
          <Button
            text="Get Started"
            className=" text-sm sm:text-xl sm:py-4 sm:px-11 font-bold w-32 sm:w-52"
            onClick={() => setIsScheduleDemoOpen(true)}
          ></Button>
        </div>
        <div className="grid grid-cols-2 sm:grid-cols-5">
          <div className="mr-16 sm:mr-0 mb-9 sm:mb-0">
            <h3 className="mb-5 sm:mb-2.5 text-oi-gray-400 font-bold text-xs">
              FEATURES
            </h3>{" "}
            <ul>
              <ul>
                <li className="font-medium sm:font-normal sm:text-base text-xs mb-3 text-white">
                  <a href="#creator-discovery">Creator Discovery</a>
                </li>
                <li className="font-medium sm:font-normal sm:text-base text-xs mb-3 text-white">
                  <a href="#creator-access">Creator Access</a>
                </li>
                <li className="font-medium sm:font-normal sm:text-base text-xs mb-3 text-white">
                  <a href="#creator-insights">Creator Insights</a>
                </li>
                <li className="font-medium sm:font-normal sm:text-base text-xs mb-3 text-white">
                  <a href="#competitive-pricing">Competitive Pricing</a>
                </li>
                <li className="font-medium sm:font-normal sm:text-base text-xs mb-3 text-white">
                  <a href="#seamless-fulfilment">Seamless Fulfilment</a>
                </li>
                <li className="font-medium sm:font-normal sm:text-base text-xs mb-3 text-white">
                  <a href="#real-time-tracking">Real Time Tracking</a>
                </li>
                <li className="font-medium sm:font-normal sm:text-base text-xs mb-3 text-white">
                  <a href="#one-ads">One Ads</a>
                </li>
              </ul>
            </ul>
          </div>
          <div>
            <h3 className="mb-5 sm:mb-2.5 text-oi-gray-400 font-bold text-xs ">
              ONE IMPRESSION
            </h3>
            <ul>
              <ul>
                <li className="font-medium sm:font-normal sm:text-base text-xs mb-3 text-white">
                  <a href="/">Home</a>
                </li>
                <li className="font-medium sm:font-normal sm:text-base text-xs mb-3 text-white">
                  <a href="/">About Us</a>
                </li>
                <li className="font-medium sm:font-normal sm:text-base text-xs mb-3 text-white">
                  <a href="#">Press</a>
                </li>
                <li className="font-medium sm:font-normal sm:text-base text-xs mb-3 text-white">
                  <a href="#">Case Studies</a>
                </li>
                <li className="font-medium sm:font-normal sm:text-base text-xs mb-3 text-white">
                  <a href="#">Blog</a>
                </li>
                <li className="font-medium sm:font-normal sm:text-base text-xs mb-3 text-white ">
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => window.open(hiringURL, "_blank")}
                  >
                    Careers (We're Hiring ✨)
                  </div>
                </li>
              </ul>
            </ul>
          </div>
          <div>
            <h3 className="mb-5 sm:mb-2.5 text-oi-gray-400 font-bold text-xs">
              CONTACT
            </h3>
            <ul>
              <ul>
                <li className="font-medium sm:font-normal sm:text-base text-xs mb-3 text-white cursor-pointer">
                  <span onClick={() => setIsScheduleDemoOpen(true)}>
                    Talk To Us
                  </span>
                </li>
                <li className="font-medium sm:font-normal sm:text-base text-xs mb-3 text-white">
                  <a
                    href="https://www.instagram.com/oneimpressionco/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Instagram
                  </a>
                </li>
                <li className="font-medium sm:font-normal sm:text-base text-xs mb-3 text-white">
                  <a
                    href="https://www.linkedin.com/company/oneimpression/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    LinkedIn
                  </a>
                </li>
                <li className="font-medium sm:font-normal sm:text-base text-xs mb-3 text-white">
                  <a
                    href="https://www.twitter.com/oneimpressionco/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitter
                  </a>
                </li>
                <li className="font-medium sm:font-normal sm:text-base text-xs mb-3 text-white">
                  <a
                    href="https://www.facebook.com/oneimpressionco/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Facebook
                  </a>
                </li>
              </ul>
            </ul>
          </div>
          <div className="sm:order-first sm:col-span-2">
            <h3 className="mb-5 sm:mb-2.5 text-oi-gray-400 font-bold text-xs">
              LEGAL
            </h3>
            <ul>
              <li className="font-medium sm:font-normal sm:text-base text-xs mb-3 text-white cursor-pointer">
                <span onClick={onTalkToUsClick}>Talk To Us</span>
              </li>
              <li className="font-medium sm:font-normal sm:text-base text-xs mb-3 text-white">
                <a href="/terms-of-service">Terms of Service</a>
              </li>
              <li className="font-medium sm:font-normal sm:text-base text-xs mb-3 text-white">
                <a href="/privacy-policy">Privacy Policy</a>
              </li>
              <li className="font-medium sm:font-normal sm:text-base text-xs mb-3 text-white">
                <a href="/brand-terms-and-conditions">
                  Brand Terms & Condition
                </a>
              </li>
              <li className="font-medium sm:font-normal sm:text-base text-xs mb-3 text-white">
                <a href="/influencer-terms-and-conditions">
                  Influencer Terms & Condition
                </a>
              </li>
              <li className="font-medium sm:font-normal sm:text-base text-xs mb-3 text-white">
                <a href="/security-announcement">Security Announcement</a>
              </li>
              <li className="font-normal sm:text-sm text-xs mb-3 text-white sm:pr-40">
                <p>
                  © {currentYear} Anycast Technology Pvt. Ltd. All rights
                  reserved.
                </p>
              </li>
              <li class="mt-6 sm:mt-11">
                <img src="oi-white-logo.svg" class="w-36 sm:w-64" />
              </li>
            </ul>
          </div>
        </div>
      </footer>
      <ScheduleDemo
        isOpen={isScheduleDemoOpen}
        setOpen={() => setIsScheduleDemoOpen(!isScheduleDemoOpen)}
      />
    </div>
  );
};
