import { Navbar } from "../components/Navbar/Navbar";
import { Footer } from "../components/Footer/Footer";
import { useState } from "react";
import { ScheduleDemo } from "../components/Modal/ScheduleDemo";

export const PrivacyPolicyLayout = ({ children }) => {
  const [isScheduleDemoOpen, setIsScheduleDemoOpen] = useState(false);
  return (
    <div className="relative overflow-hidden">
      <Navbar />

      {children}
      <hr className="bg-oi-gray-100 mt-24 h-px" />
      <Footer />
      <ScheduleDemo
        isOpen={isScheduleDemoOpen}
        setOpen={() => setIsScheduleDemoOpen(!isScheduleDemoOpen)}
      />
    </div>
  );
};
