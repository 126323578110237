import React from "react";

// UTILS
import { LINK_TYPES } from "../../utils/commonUtils";

const { MAIL, PHONE } = LINK_TYPES;

const Link = ({ href, text, type, className }) => {
  const underline = type === PHONE ? "" : "underline";
  if (type === MAIL) {
    href = `${MAIL}:${href}`;
  }
  if (type === PHONE) {
    href = `${PHONE}:${href}`;
  }
  return (
    <a
      href={href}
      target="_blank"
      className={`text-blue-600 ${underline} ${className}`}
    >
      {text}
    </a>
  );
};
const FontBold = ({ text }) => <span className="font-bold">{text}</span>;
const Space = () => <>&nbsp;</>;

export { Link, FontBold, Space };
