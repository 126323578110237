import { PrivacyPolicyLayout } from "../../layouts/PrivacyPolicy";

export const Legal = () => {
  return (
    <PrivacyPolicyLayout>
      <div className="max-w-screen-xl mx-auto px-5 mt-5">
        <div>
          <h2 className="text-5xl font-bold">Legal Center</h2>
          <h3 className="mt-5 font-bold text-xl">Contacts:</h3>
          <div className="md:pl-10 pl-5 mt-5">
            <ul className="space-y-8 list-disc">
              <li className=" md:text-base text-sm">
                General legal inquiries:{" "}
                <a
                  href="mailto:legal@oneimpression.io"
                  className="ml-2 text-oi-teal-400"
                >
                  legal@oneimpression.io
                </a>
              </li>
              <li className="md:text-base text-sm">
                Privacy inquiries:{" "}
                <a
                  href="mailto:legal@oneimpression.io"
                  className="ml-2 text-oi-teal-400"
                >
                  legal@oneimpression.io
                </a>
              </li>
              <li className="md:text-base text-sm">
                Security inquiries:{" "}
                <a
                  href="mailto:legal@oneimpression.io"
                  className="ml-2 text-oi-teal-400"
                >
                  legal@oneimpression.io
                </a>
              </li>
              <li className="md:text-base text-sm">
                Billing inquiries:{" "}
                <a
                  href="mailto:finance@oneimpression.io"
                  className="ml-2 text-oi-teal-400"
                >
                  finance@oneimpression.io
                </a>
              </li>
            </ul>
          </div>
          <h3 className="mt-8 font-bold text-xl">Agreements:</h3>
          <div className="md:pl-10 pl-5 mt-5">
            <ul className="space-y-8 list-disc">
              <li className=" md:text-base text-sm">
                <a href="/privacy-policy" className="ml-2 text-oi-teal-400">
                  Privacy Policy
                </a>
              </li>
              <li className=" md:text-base text-sm">
                <a
                  href="/terms-and-conditions"
                  className="ml-2 text-oi-teal-400"
                >
                  Influencer Terms & Conditions
                </a>
              </li>
              <li className=" md:text-base text-sm">
                <a
                  href="/influencer-management-contract"
                  className="ml-2 text-oi-teal-400"
                >
                  Influencer Management Contract
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </PrivacyPolicyLayout>
  );
};
