import { GetStartedLayout } from "../../layouts/GetStarted";
import { GetStartedForm } from "../../Sections/GetStarted/ContactForm/ContactForm";

export const GetStarted = () => {
	return (
		<GetStartedLayout>
			<GetStartedForm />
		</GetStartedLayout>
	);
};
