export const KPISection = () => {
  return (
    <div
      className="sm:grid-cols-2 sm:mt-44 sm:mx-auto grid grid-cols-1 mt-16 gap-x-24 max-w-screen-xl mx-4"
      id="seamless-fulfilment"
    >
      <div className="flex flex-col sm:mr-1.5">
        <div className="font-bold text-2xl sm:text-5xl sm:font-bold">
          Manage influencer campaigns at scale with speed & seamlessness.
        </div>
        <div className="mt-6">
          Our platform simplifies influencer campaigns, enabling brands to scale
          rapidly. From discovery to pricing, contracting to payments, briefing
          to performance, we have got everything covered.
        </div>
      </div>
      <div className="grid sm:grid-cols-2 sm:gap-y-8 grid-cols-2 mt-8 sm:mt-0 w-full items-center gap-y-6 ">
        <div>
          <div className="sm:font-bold sm:text-5xl text-2xl text-oi-teal-300">
            7 Million+
          </div>
          <div className="mt-4 font-normal text-sm sm:text-xl text-oi-gray-400">
            Accessible Creators Globally
          </div>
        </div>
        <div>
          <div className="sm:font-bold sm:text-5xl text-2xl text-oi-teal-300">
            50,000+
          </div>
          <div className="mt-4 font-normal text-sm sm:text-xl text-oi-gray-400">
            Content Created Yearly
          </div>
        </div>
        <div>
          <div className="sm:font-bold sm:text-5xl text-2xl text-oi-teal-300">
            2 Billion+
          </div>
          <div className="mt-4 font-normal text-sm sm:text-xl text-oi-gray-400">
            Views Generated on Social Platforms
          </div>
        </div>
        <div style={{ marginTop: "-28px" }}>
          <div className="sm:font-bold sm:text-5xl text-2xl text-oi-teal-300">
            500+
          </div>
          <div className="mt-4 font-normal text-sm sm:text-xl text-oi-gray-400">
            Brands Who Trust Us
          </div>
        </div>
      </div>
    </div>
  );
};
