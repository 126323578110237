import { useState } from "react";
import { ScheduleDemo } from "../components/Modal/ScheduleDemo";

export const GetStartedLayout = ({ children }) => {
	const [isScheduleDemoOpen, setIsScheduleDemoOpen] = useState(false);
	return (
		<div className="relative overflow-hidden">
			{children}
			<ScheduleDemo
				isOpen={isScheduleDemoOpen}
				setOpen={() => setIsScheduleDemoOpen(!isScheduleDemoOpen)}
			/>
		</div>
	);
};
