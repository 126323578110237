import { PrivacyPolicyLayout } from "../../layouts/PrivacyPolicy";

export const InfluencerManagementContract = () => {
  return (
    <PrivacyPolicyLayout>
      <div className="max-w-screen-xl mx-auto px-5 mt-8 lg:mt-16">
        <div className="text-4xl not-italic font-bold font-sans text-oi-gray-900 tracking-wider mb-5">
          INFLUENCER MANAGEMENT CONTRACT
        </div>
        <div className="mb-5">
          <div className="text-base not-italic font-normal font-sans text-black mb-2">
            This Influencer Management Contract (“Contract”) is between You
            (hereinafter referred to as “Customer”) and Anycast Technology
            Private Limited, a company incorporated under the Companies Act,
            2013 and having its registered office at 1st Floor, Landmark Cyber
            Park Sector-67, Gurgaon Haryana 122018 IN (hereinafter referred to
            as "One Impression).
          </div>
          <div className="text-base not-italic font-normal font-sans text-black mb-2">
            Customer and One Impression are hereinafter collectively referred to
            as the ‘Parties’ and each a ‘Party’.
          </div>
        </div>
        <h3 className="mt-5 font-bold text-xl">WHEREAS:</h3>
        <div className="md:pl-10 pl-5 mt-1">
          <ul className="space-y-2 list-decimal">
            <li className=" md:text-base text-sm">
              One Impression is engaged in the business of facilitating and
              creating alliances by way of Influencer association with brands
              and allied services.
            </li>
            <li className="md:text-base text-sm">
              The Customer has approached One Impression to become their
              partners for providing digital marketing related services
              pertaining to Customer’s range of Services for its Campaigns and
              One Impression has agreed to provide the Services to Customer, on
              the terms and subject to the conditions of this Contract.
            </li>
            <li className="md:text-base text-sm">
              Based on the aforesaid representation, the Customer has agreed to
              engage the services of the One Impression subject to the terms and
              conditions set out herein.
            </li>
          </ul>
        </div>
        <div>
          <h3 className="mt-5 font-bold text-xl">
            NOW, THEREFORE, in consideration of the mutual covenants set forth
            herein and for other good and valuable consideration, the receipt
            and sufficiency of which is hereby acknowledged, it is agreed
            between the Parties as follows:
          </h3>
          <h3 className="mt-5 font-bold text-xl">DEFINITIONS</h3>
          <div className="text-base not-italic font-normal font-sans text-black mb-2 mt-5">
            In this Contract, including in the recitals hereof, the following
            words, expressions and abbreviations shall have the following
            meanings, unless the context otherwise clearly indicates a contrary
            intention:
          </div>
          <div className="text-base font-sans text-black mb-2 mt-5">
            <b>“CAMPAIGN”</b> means a marketing activity where identified
            influencers performs the pre- agreed services between the parties;
          </div>
          <div className="text-base font-sans text-black mb-2 mt-5">
            “CONTRACT” means this entire Contract and any Schedule(s),
            Annexure(s), Addendum(s) forming part of this Contract;
          </div>
          <div className="text-base font-sans text-black mb-2 mt-5">
            "APPLICABLE LAW" means the substantive or procedural laws of India,
            including all legislations, acts, rules, notifications, laws,
            statutes, orders, decrees, judgments, injunctions, ordinances,
            directives, regulations, codes, requirements, permits, licenses,
            approvals, instructions, standards of any government
            instrumentality, having the force of law;
          </div>
          <div className="text-base font-sans text-black mb-2 mt-5">
            “BUSINESS DAYS” means a day (excluding Saturdays and Sundays) on
            which banks are open for business in New Delhi;
          </div>
          <div className="text-base font-sans text-black mb-2 mt-5">
            “CONFIDENTIAL INFORMATION" “Confidential Information” means all
            nonpublic information, in any form and on any medium, whether or not
            designated by a party (the “Disclosing Party”) as confidential and
            disclosed by such party to the other party (the “Receiving Party”)
            under this Contract, regardless of the form of disclosure, and
            includes without limitation and without the need to designate as
            confidential and related documents and information; BUT DOES NOT
            INCLUDE any information to the extent, but only to the extent, that
            such information becomes or is: (a) already known to or in the
            possession of the Receiving Party free of any restriction at the
            time it is obtained from the Disclosing Party; (b) subsequently
            learned by the Receiving Party from an independent third party free
            of any restriction and without breach of this Contract or any
            Contract with such third party; (c) publicly available through no
            wrongful act of the Receiving Party; or (d) independently developed
            by the Receiving Party without reference to any Confidential
            Information, as evidenced by the ordinary business records of the
            Receiving Party. The identity of Influencer and all other details
            related thereto referred under this Contract shall constitute the
            Confidential Information of One Impression.
          </div>
          <div className="text-base font-sans text-black mb-2 mt-5">
            “CONTENT” shall mean such material used for the promotion of the
            Brand and its products including but not limited to advertisements,
            articles, blog posts, comments, photos, audio or video recordings,
            testimonials etc;
          </div>
          <div className="text-base font-sans text-black mb-2 mt-5">
            “CUSTOMER” means the client of One Impression as mentioned in the
            Order Form (OF);
          </div>
          <div className="text-base font-sans text-black mb-2 mt-5">
            “DISCLOSING PARTY” means the party providing information when the
            Receiving Party is recipient of all such information shared by
            disclosing party;
          </div>
          <div className="text-base font-sans text-black mb-2 mt-5">
            “INTELLECTUAL PROPERTY RIGHTS” means any, and all, now known or
            hereafter known tangible and intangible rights vested with the
            Parties, as the case may be, and shall include (a) licenses rights,
            (b) trade and service marks, (c) trade or business names, domain
            names, (d) rights in designs, copyrights, for the full term of such
            rights and including any extension to or renewal of the terms of
            such rights, an including registrations and applications for
            registration of any of these and rights to apply for the same, and
            all rights and forms of protection of a similar nature or having
            equivalent or similar effect to any of these anywhere in the world,
            (e) other intellectual or industrial property rights (of each kind
            and nature throughout the universe and however designated)
            (including logos, “rental” rights and rights to remuneration),
            whether arising by operation of law, contract, license, or
            otherwise;
          </div>
          <div className="text-base font-sans text-black mb-2 mt-5">
            “RUPEES” or “INR” or “Rs.” means the lawful currency of India;
          </div>
          <div className="text-base font-sans text-black mb-2 mt-5">
            “INFLUENCER” shall mean Influencer itself, including any bloggers,
            critics, columnists, experts, influencers etc. engaged by the One
            Impression, for the purposes of this Contract;
          </div>
          <div className="text-base font-sans text-black mb-2 mt-5">
            “VALID INVOICE” shall mean such an invoice which is in compliance
            with the requirements prescribed under the Central Goods and Service
            Tax Act, 2017, Integrated Goods and Services Tax Act, 2017, relevant
            State Goods and Services Tax Act and Union Territory Goods and
            Services Act, and the Rules framed therein (hereinafter defined as
            ‘GST Laws’).
          </div>
        </div>
        <h3 className="mt-5 font-bold text-xl">INTERPRETATION</h3>
        <div className="mt-5">
          <div className="text-base not-italic font-normal font-sans text-black mb-2">
            The paragraph titles of this Contract are for convenience only and
            shall not affect the interpretation of this Contract or any
            paragraph thereof.
          </div>
          <div className="text-base not-italic font-normal font-sans text-black mb-2">
            Unless the context otherwise requires, words importing the singular
            only shall include the plural and vice versa, words importing one
            gender only shall include all other genders and words importing
            natural persons shall include corporations. Words and phrases which
            appear with initial letters capitalized are “Defined Terms”, If not
            defined where they first appear, Defined Terms are defined either in
            the Contract or in accordance with industry custom and practice.
          </div>
        </div>
        <h3 className="mt-5 font-bold text-xl">SCOPE OF WORK</h3>
        <div className="mt-5">
          <div className="text-base not-italic font-normal font-sans text-black mb-2">
            The Customer hereby engages the One Impression for the services as
            detailed in the Order Form (OF) each time agreed by both parties for
            each Campaign (“Services”).
          </div>
        </div>
        <h3 className="mt-5 font-bold text-xl">FEES</h3>
        <div className="mt-5">
          <div className="text-base not-italic font-normal font-sans text-black mb-2">
            In consideration for the Services rendered by the One Impression,
            the Customer agrees to pay the amount for each campaign as agreed
            between the parties will be mentioned in the Order Form (OF) at the
            time of execution of each Campaign. The Fees is exclusive of
            applicable taxes. The One Impression shall provide the Customer with
            a Valid Invoice. Upon receipt of the Valid Invoice, the Customer
            shall make the payment towards the invoice amount as agreed and
            mentioned under executed Order Form (OF).
          </div>
        </div>
        <h3 className="mt-5 font-bold text-xl">TERM</h3>
        <div className="mt-5">
          <div className="text-base not-italic font-normal font-sans text-black mb-2">
            This Contract shall commence from the execution date of Order Form
            (OF) (the “Effective Date”) and remain in full force till the
            Service Completion date as mentioned in the Order Form (OF) unless
            either Party terminates the same in terms of Clause 12 (Termination)
            of this Contract (“Term”).
          </div>
        </div>
        <h3 className="mt-5 font-bold text-xl">
          REPRESENTATIONS AND WARRANTIES
        </h3>
        <h3 className="mt-5 font-bold text-xl">
          Customer represents and warrants to the One Impression that:
        </h3>
        <div className="mt-5">
          <div className="text-base not-italic font-normal font-sans text-black mb-4">
            It has taken all required corporate, statutory and other approvals
            for enabling it to sign, execute and deliver this Contract and the
            person signing this Contract has been duly authorized to sign,
            execute and deliver this Contract and to bind such Party;
          </div>
          <div className="text-base not-italic font-normal font-sans text-black mb-4">
            This Contract is the legal, valid and binding obligation of such
            party, enforceable against such Party, in accordance with its
            respective terms;
          </div>
          <div className="text-base not-italic font-normal font-sans text-black mb-4">
            No consent, authorization, approval or other action by, and no
            notice to or filing with, any governmental authority or regulatory
            body or other third party is required for the execution, delivery or
            performance of this Contract by Customer; and
          </div>
          <div className="text-base not-italic font-normal font-sans text-black mb-4">
            The execution of the Contract and delivery and performance of its
            duties, obligation and responsibilities arising or created under or
            pursuant to this Contract does not conflict with, result in a breach
            of or default under applicable law, or any order, writ, injunction
            or decree of any Court or governmental authority or any Contract,
            written or oral, to which it is a party.
          </div>
          <div className="text-base not-italic font-normal font-sans text-black mb-4">
            Customer shall be responsible for the product delivered/services
            mentioned for the purpose of promotions in the Campaign and shall
            ensure that the products shall be complied with the applicable laws,
            applicable standards or any other requirement as applicable for such
            category of product.
          </div>
          <div className="text-base not-italic font-normal font-sans text-black mb-4">
            Neither One Impression nor the Influencer has any way of
            ascertaining and verifying the ingredients, quality and legal
            compliance of the Products/Service and are completely relying on the
            representations made by the Customer in this regard.
          </div>
          <div className="text-base not-italic font-normal font-sans text-black mb-4">
            Customer shall not require the Influencer to make any claims
            relating to the quality, efficacy or any other quantitative or
            qualitative aspect of the Product/Service or the Customer.
          </div>
          <div className="text-base not-italic font-normal font-sans text-black mb-4">
            Customer represents that it has documents to support the
            Product/Service claims that the Influencer is required to make in
            the Materials promoting the Campaign and the Customer shall share
            the same with the One Impression at the time of sharing Campaign
            breif. One Impression on behalf of the Influencer is relying on this
            representation made by the Customer and shall not be liable for any
            claims arising in this regard.
          </div>
          <div className="text-base not-italic font-normal font-sans text-black mb-4">
            Customer while providing /approving content, using or exploiting the
            Materials shall adhere to all the applicable advertising laws
            including but not limited to Advertising Standard Council of India
            rules, guidelines in existence or which may come into existence in
            future relating to digital marketing. The Customer shall solely be
            liable for any claims arising in this regard. The Customer shall
            disclose all material information and provide the appropriate labels
            that the Influencer has to use in the Material for complying with
            applicable guidelines including but not limited to ASCI guidelines
            for digital/influencer marketing.
          </div>
          <div className="text-base not-italic font-normal font-sans text-black mb-4">
            In case of any issue/claim raised in the delivered product/Services
            (which was duly approved and/or provided by the Customer) for the
            purpose of advertising, promotions, marketing in the campaign by the
            Influencers then the Customer shall at its own cost, expense and
            risk and responsibility promptly defend, pay and settle all such
            third-party claims and actions including claims raised by ASCI and
            or any governing and/or non-governing bodies in such manner as
            Customer may deem fit without any recourse to One
            Impression/Influencer.
          </div>
        </div>
        <h3 className="mt-5 font-bold text-xl">
          GENERAL OBLIGATIONS OF ONE IMPRESSION
        </h3>
        <div className="mt-5">
          <div className="text-base not-italic font-normal font-sans text-black mb-4">
            One Impression shall provide the Services in good faith and as per
            the best industrial standards.
          </div>
          <div className="text-base not-italic font-normal font-sans text-black mb-4">
            This Contract is the legal, valid and binding obligation of such
            party, enforceable against such Party, in accordance with its
            respective terms;If an Influencer, brought in by One Impression,
            does not perform in the campaign due to any reason or circumstances,
            whatsoever, the Customer may take any of the following actions:
          </div>
          <div className="text-base not-italic font-normal font-sans text-black mb-4">
            {"a)"} Remove the influencer from the campaign and direct the One
            Impression to hire another influencer on the same terms as
            applicable to the influencer performing the campaign; or
          </div>
          <div className="text-base not-italic font-normal font-sans text-black mb-4">
            {"b)"} In case of payment already made by the Customer, then
            Customer shall be entitled to claim refund on proportionate basis or
            make adjustments for the paid amount. The above-mentioned clause is
            applicable only in case of non-performance services by the
            Influencer.
          </div>
          <div className="text-base not-italic font-normal font-sans text-black mb-4">
            One Impression, shall in its capacity, facilitate the Influencer to
            comply with its obligations in accordance to the Contract. It is
            clarified that neither of the parties shall be responsible for any
            act done by Influencer using unapproved content/material during the
            course of engagement and the Influencer shall be directly and solely
            liable for all such acts undertaken by him/her for the unapproved
            content.
          </div>
        </div>
        <h3 className="mt-5 font-bold text-xl">CONFIDENTIAL INFORMATION</h3>
        <div className="mt-5">
          <div className="text-base not-italic font-normal font-sans text-black mb-4">
            The Receiving Party will: (a) use the Disclosing Party’s
            Confidential Information only during the Term and only as necessary
            to perform the Receiving Party’s obligations under this Contract;
            (b) disclose the Disclosing Party’s Confidential Information only to
            the Receiving Party’s or its Affiliates’ directors, officers,
            employees, representatives, and subcontractors, on a “need to know
            basis” and only to the extent that such disclosure is necessary to
            perform the Receiving Party’s obligations under this Contract; and
            (c) both during and for a period of one (1) year after the Term
            (except such period shall be perpetual with respect to trade secrets
            of a party for so long the information is a trade secret) maintain
            the strict confidentiality of the Disclosing Party’s Confidential
            Information using the same degree of care as the Receiving Party
            affords to its own confidential information of a similar nature
            which it desires not to be published or disseminated, and in no
            event less than reasonable care, to prevent the unauthorized use or
            disclosure of the Disclosing Party’s Confidential Information.
          </div>
          <div className="text-base not-italic font-normal font-sans text-black mb-4">
            Receiving Party may disclose Confidential Information of the
            Disclosing Party if it is required to be disclosed by law or at the
            request of any governmental body or court of law provided that the
            Receiving Party shall notify the Disclosing Party, to the extent
            permitted by law, of such requirement as soon as practicable.
          </div>
          <div className="text-base not-italic font-normal font-sans text-black mb-4">
            Customer cannot make statements to the press or any media service or
            upon any public platform regarding this Contract and engagement
            between the parties or its terms without prior approval of One
            Impression.
          </div>
        </div>
        <h3 className="mt-5 font-bold text-xl">INTELLECTUAL PROPERTY RIGHTS</h3>
        <div className="mt-5">
          <div className="text-base not-italic font-normal font-sans text-black mb-4">
            All documents, data, systems, software or any other material,
            together with all the intellectual property rights of the Customer
            like patents, copyrights, trademarks, trade names, domain names,
            designs, goodwill, market reputation etc., relating to Customer,
            disclosed, supplied or furnished by the Customer to One Impression
            (“Customer Content”) shall always remain vested in Customer and in
            no circumstances the One Impression shall claim any rights and/or
            use the same under the pretext of this Contract. One Impression has
            rights to use this information for the purpose of Deliverables /
            Services under this Contract.
          </div>
          <div className="text-base not-italic font-normal font-sans text-black mb-4">
            Customer acknowledges that by virtue of this Contract it obtains no
            license or right to any copyright, trademark, trade name or
            Intellectual Property Right owned by One Impression and it shall
            make no use whatsoever of any of them without One Impression’s prior
            written consent.
          </div>
        </div>
        <h3 className="mt-5 font-bold text-xl">INDEMNITY</h3>
        <div className="mt-5">
          <div className="text-base not-italic font-normal font-sans text-black mb-4">
            Each Party (the “Indemnifying Party”) shall protect, indemnify and
            save harmless the other Party from and against any and all damages,
            claims (including settlement costs), suits, proceedings (including
            any proceeding brought before any court, regulatory body,
            arbitration panel or other tribunal), actions, judgments, and
            expenses whatsoever (including reasonable legal fees) arising out of
            or in any way connected with (a) any act or omission of the
            Indemnifying Party; (b) arising out of material breach of the terms
            of this Contract; (c) a breach or non-performance of any of its
            representations, warranties, covenants and/or assurances contained
            herein by any Party; (d) failure by the Party to perform any of its
            obligations contained herein; and (iii) a breach or non-performance
            by the Party of any applicable law. Whereas, the above mentioned
            clause shall not be applicable if any act arises due to the content
            or material provided or approved by the Customer for performance in
            the Campaign.
          </div>
          <div className="text-base not-italic font-normal font-sans text-black mb-4">
            In case of any default or failure of the Influencer in discharging
            its obligations under this Contract or as a result of any act or
            omission on part of the Influencer appointed by One Impression or/
            and in case of infringement of intellectual property rights of any
            third party, One Impression shall not be liable and Influencer shall
            be directly liable for any such act. Whereas, neither the One
            Impression nor the Influencer shall be liable for any content
            provided by/approved by the Customer for performance in the
            Campaign.
          </div>
          <div className="text-base not-italic font-normal font-sans text-black mb-4">
            LIMITATION OF LIABILITY: NOTWITHSTANDING ANY OTHER PROVISION OF THIS
            CONTRACT, UNDER NO CIRCUMSTANCES WILL EITHER PARTY’S TOTAL AGGREGATE
            LIABILITY TO THE OTHER PARTY IN CONNECTION WITH CLAIMS ARISING UNDER
            OR PERTAINING TO THIS CONTRACT AND/OR THE SERVICES EXCEED THE TOTAL
            AMOUNT PAID OR PAYABLE TO ONE IMPRESSION UNDER THE RELEVANT ORDER
            FORM.
          </div>
        </div>
        <h3 className="mt-5 font-bold text-xl">TERMINATION</h3>
        <div className="mt-5">
          <div className="text-base not-italic font-normal font-sans text-black mb-4">
            Either Party may, at any time, for its convenience, terminate this
            contract for no reason whatsoever, by giving the other party, a 30
            (thirty) days prior written notice of termination. During the
            termination period the work completed by One Impression shall be
            paid for by Customer within 7 working days of the end of the
            contract.
          </div>
          <div className="text-base not-italic font-normal font-sans text-black mb-4">
            Either party may terminate this contract upon 15 (fifteen) days
            prior written notice if the other party breaches this Contract and
            does not cure such breach within such time period. In addition to
            any right or remedy that may be available to the parties under this
            Contract or applicable law, In addition, in the event that the
            Customer has breached this Contract, One Impression may instruct the
            Influencer to cease all promotional activities or make clarifying
            statements, and the Influencer shall immediately comply.
          </div>
        </div>
        <h3 className="mt-5 font-bold text-xl">NOTICE</h3>
        <div className="mt-5">
          <div className="text-base not-italic font-normal font-sans text-black mb-4">
            All notices must be in writing and addressed to the attention of the
            other party’s legal department or the contact provided in the Order
            Form (OF). Notice will be deemed given upon receipt if verifiable by
            trusted logs or receipts (electronic or otherwise) to the last
            provided contact information. Each party is responsible for keeping
            the other informed of changes to its contact information. Service of
            any legal proceedings concerning or arising out of this Contract
            shall be affected by causing the same to be delivered to the Party
            to be served, at its registered office, or to such other address as
            may from time to time be notified in writing by the Party concerned.
          </div>
        </div>
        <h3 className="mt-5 font-bold text-xl">FORCE MAJEURE</h3>
        <div className="mt-5">
          <div className="text-base not-italic font-normal font-sans text-black mb-4">
            Either Party shall not be liable for any failure or delay on its
            part in performing its obligations under this Contract, if such
            failure or delay is due in whole or in part, to “Force Majeure”
            conditions. Force Majeure for the purpose of this Contract, shall
            include, labour strike, fires, earthquakes, floods, other Acts of
            God, wars or acts of war, the outbreak of hostilities (regardless of
            whether war is declared), terrorist acts, embargoes, blockades,
            sanctions, revolutions, riots, civil commotions, sabotage, strikes,
            or any other causes beyond the control of the affected Party. Both
            the parties to the contract shall have a right to terminate this
            Contract in the event any Party is unable to perform as per the
            terms of this Contract for a continuous period of 30 days.
          </div>
        </div>
        <h3 className="mt-5 font-bold text-xl">ARBITRATION</h3>
        <div className="mt-5">
          <div className="text-base not-italic font-normal font-sans text-black mb-4">
            In case of any dispute between the parties to the Contract then,
            either Party may provide notice to the other Party of its intention
            to commence arbitration. Disputes shall be referred to a sole
            arbitrator mutually agreed and appointed by the Parties within
            fifteen (15) days of a Party referring a Dispute to the other. If
            the Parties fail to agree on the appointment of the sole arbitrator
            within the time specified, either Party shall have the right to
            approach the Court of appropriate jurisdiction at Gurugram, India
            seeking appointment of Arbitrator in terms of the Arbitration and
            Conciliation Act, 1996 or any statutory or modification thereof. The
            sole arbitrator shall pass a reasoned award which shall be final and
            binding upon the Parties.
          </div>
          <div className="text-base not-italic font-normal font-sans text-black mb-4">
            The arbitration proceedings shall be conducted in the English
            Language and in Gurugram. Provided however, nothing contained in
            this Clause shall be deemed to prevent a Party from approaching a
            Court of competent jurisdiction at Gurugram for seeking relief prior
            to or pending arbitration. Unless otherwise decided, each Party
            shall bear their own costs for arbitration.
          </div>
        </div>
        <h3 className="mt-5 font-bold text-xl">GOVERNING LAW & SEVERANCE</h3>
        <div className="mt-5">
          <div className="text-base not-italic font-normal font-sans text-black mb-4">
            This Contract, including non-contractual rights and obligations
            arising out of or in connection with this Contract, shall be
            governed by the laws of India. Any legal proceedings arising out of
            this Contract or relating thereto, shall be instituted in Courts of
            Gurugram Only.
          </div>
          <div className="text-base not-italic font-normal font-sans text-black mb-4">
            If any provision or part-provision of this Contract is or becomes
            invalid, illegal or unenforceable, it shall be deemed modified to
            the minimum extent necessary to make it valid, legal and
            enforceable. If such modification is not possible, the relevant
            provision or part-provision shall be deemed deleted. Any
            modification to or deletion of a provision or part-provision under
            this clause shall not affect the validity and enforceability of the
            rest of this Contract, unless such above mentioned contravening
            provision relates to a material part of this Contract and / or is
            not severable, in which case, the whole Contract would be terminated
            by way of the Parties' Contract or an arbitral award.
          </div>
        </div>
        <h3 className="mt-5 font-bold text-xl">SURVIVAL</h3>
        <div className="mt-5">
          <div className="text-base not-italic font-normal font-sans text-black mb-4">
            The following clauses survive the expiration or cancellation of this
            Contract: Intellectual Property Rights; Indemnity; Arbitration;
            Governing Law & Severance shall survive the termination or expiry of
            this Contract or Any terms of this agreement that by their nature
            extend beyond its expiration or termination remain in effect until
            fulfilled
          </div>
        </div>
        <h3 className="mt-5 font-bold text-xl">ASSIGNMENT</h3>
        <div className="mt-5">
          <div className="text-base not-italic font-normal font-sans text-black mb-4">
            Neither party may assign this Contract in whole or in part to any of
            its affiliates/ group companies without prior written consent of the
            other party.
          </div>
        </div>
        <h3 className="mt-5 font-bold text-xl">RELATIONSHIP</h3>
        <div className="mt-5">
          <div className="text-base not-italic font-normal font-sans text-black mb-4">
            This Contract is not intended to create nor shall be construed to
            create any relationship between the Parties other than that of
            independent entities contracting for the purpose of effecting the
            provisions of this Contract. Neither of the Parties nor any of their
            representatives shall be construed to be the agent, employer,
            employee or representative of the other. Additionally, nothing in
            the execution of this Contract or in its performance shall be
            construed to establish a joint venture by or partnership between the
            Parties.
          </div>
        </div>
        <h3 className="mt-5 font-bold text-xl">WAIVER</h3>
        <div className="mt-5">
          <div className="text-base not-italic font-normal font-sans text-black mb-4">
            No waiver by either party, whether express or implied, of any
            provision of this Contract, or of any breach or default of either
            party, shall constitute a continuing waiver of such provisions of
            this Contract.
          </div>
        </div>
        <h3 className="mt-5 font-bold text-xl">HEADINGS</h3>
        <div className="mt-5">
          <div className="text-base not-italic font-normal font-sans text-black mb-4">
            The paragraph and subparagraph headings contained in this Contract
            are of convenience and reference only. They are not intended to
            define or limit the scope of any provisions of this Contract.
          </div>
        </div>
        <h3 className="mt-5 font-bold text-xl">PARTIAL INVALIDITY</h3>
        <div className="mt-5">
          <div className="text-base not-italic font-normal font-sans text-black mb-4">
            Should any article or part of an article of this Contract be held
            unenforceable or in conflict with the law of jurisdiction, the
            validity of the remaining articles and subsections of articles shall
            not be affected by such holding.
          </div>
        </div>
        <h3 className="mt-5 font-bold text-xl">ENTIRE CONTRACT</h3>
        <div className="mt-5">
          <div className="text-base not-italic font-normal font-sans text-black mb-4">
            This Contract, including and together with any related annexure,
            sets forth the entire Contract and understanding between the Parties
            with respect to the subject matter here of and supersedes and
            cancels all earlier discussions and negotiations of understandings,
            Contracts, whether written or oral, express or implied, between
            them.
          </div>
        </div>
        <h3 className="mt-5 font-bold text-xl">CONTACT</h3>
        <div className="mt-5">
          <div className="text-base not-italic font-normal font-sans text-black mb-4">
            <p>You may contact ONE IMPRESSION via: Telephone: +91-9888761888</p>
            <p>
              E-mail:{" "}
              <a
                className="text-oi-teal-400"
                href="mailto:hello@oneimpression.io"
              >
                hello@oneimpression.io
              </a>{" "}
            </p>{" "}
          </div>
        </div>
        <h3 className="mt-5 font-bold text-xl">REVISION</h3>
        <div className="mt-5">
          <div className="text-base not-italic font-normal font-sans text-black mb-4">
            We often update this ‘Influencer Management Contract’ on this
            Platform. As a result, we may change/modify/ alter/ update/ add or
            discontinue any terms and conditions laid down herein.
          </div>
          <div className="text-base not-italic font-normal font-sans text-black mb-4">
            You can determine the applicable terms by referring to the "LAST
            UPDATED" legend below. It shall be your responsibility to check
            terms periodically for changes. Please check this page regularly to
            ensure you are familiar with the updated Terms & Conditions. By
            using One Impression in any manner whatsoever and in any capacity
            whatsoever, it shall be deemed that you have seen the updated terms
            and conditions and consented thereto.
          </div>
        </div>
        <h3 className="mt-5 font-bold text-xl">
          Uploaded on: 7th February 2022
        </h3>
      </div>
    </PrivacyPolicyLayout>
  );
};
