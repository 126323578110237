import React, { useState } from "react";
import { Button } from "../../components/Button/Button";
import { MainLayout } from "../../layouts/Main";
import {
  WhatsappIcon,
  CheckGreenIcon,
} from "../../components/IconStore/IconStore";
import { BrandLogos } from "../../Sections/Home/BrandLogos/BrandLogos";
import { ScheduleDemo } from "../../components/Modal/ScheduleDemo";
import { KPISection } from "../../Sections/Home/KPISection/KPISection";
import { Event } from "../../components/Tracking";
import { hiringURL } from "../../utils/commonUtils";

const caseStudies = [
  {
    id: 1,
    img: "youtube.png",
    data: {
      brand: "Unilever",
    },
  },
  {
    id: 2,
    img: "youtube.png",
    data: {
      brand: "Mamaearth",
    },
  },
  {
    id: 3,
    img: "youtube.png",
    data: {
      brand: "Meesho",
    },
  },
  {
    id: 4,
    img: "youtube.png",
    data: {
      brand: "PubG",
    },
  },
  {
    id: 5,
    img: "youtube.png",
    data: {
      brand: "Instagram",
    },
  },
];

// const FEATURED_IMAGES = [
//   {
//     src: "/news/quartz.png",
//     link: "https://qz.com/india/1720880/instagram-youtube-tiktok-stars-are-clicking-with-indian-brands/",
//   },
//   {
//     src: "/news/moneycontrol.png",
//     link: "",
//   },
//   {
//     src: "/news/outlook.png",
//     link: "",
//   },
//   {
//     src: "/news/business-insider.png",
//     link: "https://www.businessinsider.in/advertising/ad-agencies/article/one-impression-eyes-to-grow-by-10x-this-year-after-becoming-a-3-million-platform-in-2019/articleshow/74219649.cms",
//   },
//   {
//     src: "/news/forbes.png",
//     link: "https://www.forbesindia.com/article/south-celebrity-special-2021/beyond-language-barriers-meet-the-social-media-influencers-of-the-south/71123/1",
//   },
//   {
//     src: "/news/economic-times.png",
//     link: "",
//   },
//   {
//     src: "/news/mint.png",
//     link: "",
//   },
//   {
//     src: "/news/the-hindu.png",
//     link: "",
//   },
// ];

const INVESTORS = [
  {
    src: "/investor/vamsi.png",
    name: "Vamsi Krishna",
    href: "https://www.linkedin.com/in/profilevamsikrishna/",
  },
  {
    src: "/investor/pulkit.png",
    name: "Pulkit Jain",
    href: "#",
  },
  {
    src: "/investor/peyush.png",
    name: "Peyush Bansal",
    href: "#",
  },
  {
    src: "/investor/gaurav.png",
    name: "Gaurav Agarwal",
    href: "#",
  },
  {
    src: "/investor/anupam.png",
    name: "Anupam Mittal",
    href: "https://www.linkedin.com/in/anupammittal007/",
  },
  {
    src: "/investor/zakir.png",
    name: "Zakir Khan",
    href: "#",
  },
  {
    src: "/investor/masoom.png",
    name: "Masoom Minawala",
    href: "#",
  },
  {
    src: "/investor/neeraj.png",
    name: "Neeraj Chopra",
    href: "#",
  },
  // {
  // 	src: "/investor/karandeep.png",
  // 	name: "Karandeep Anand",
  // 	href: "https://www.linkedin.com/in/karandeep/",
  // },
  // {
  // 	src: "/investor/ramakant.png",
  // 	name: "Ramakant Sharma",
  // 	href: "https://www.linkedin.com/in/sharmaramakant/",
  // },
  // {
  // 	src: "/investor/anand.png",
  // 	name: "Anand Prakash",
  // 	href: "https://www.linkedin.com/in/anand-prakash-94b90ba/",
  // },
  // {
  // 	src: "/investor/manish.png",
  // 	name: "Manish Aggarwal",
  // 	href: "https://www.linkedin.com/in/manish-agarwal-6586482/",
  // },
  // {
  // 	src: "/investor/varun.png",
  // 	name: "Varun Alagh",
  // 	href: "https://www.linkedin.com/in/varunalagh84/",
  // },
  // {
  // 	src: "/investor/ravi.png",
  // 	name: "Ravi Bhushan",
  // 	href: "https://www.linkedin.com/in/ravibk/",
  // },
  // {
  // 	src: "/investor/akshat.png",
  // 	name: "Akshat Rathee",
  // 	href: "https://www.linkedin.com/in/akshatrathee/",
  // },
  // {
  // 	src: "/investor/arjun.png",
  // 	name: "Arjun Vaidya",
  // 	href: "https://www.linkedin.com/in/arjunvaidya/",
  // },
  // {
  // 	src: "/investor/rohit.jpg",
  // 	name: "Rohit M A",
  // 	href: "https://www.linkedin.com/in/rohitoncloud9/",
  // },
  // {
  // 	src: "/investor/saahil.jpg",
  // 	name: "Saahil Goel",
  // 	href: "https://www.linkedin.com/in/saahilgoel/",
  // },
  // {
  // 	src: "/investor/giridhar.jpg",
  // 	name: "Giridhar Malpani",
  // 	href: "https://www.linkedin.com/in/giridhar-malpani-b65323148/",
  // },
  // {
  // 	src: "/investor/manish-wow.png",
  // 	name: "Manish Chowhary",
  // 	href: "https://www.linkedin.com/in/manish-chowdhary-57822612b/",
  // },
  // {
  // 	src: "/investor/navin.jpg",
  // 	name: "Navin Honagudi",
  // 	href: "https://www.linkedin.com/in/navinhonagudi/",
  // },
  // {
  // 	src: "/investor/ajay.jpg",
  // 	name: "Ajay Nair",
  // 	href: "https://www.linkedin.com/in/ajay-nair-8712273/",
  // },
];

const Team = ({ isMobile }) => {
  const images = [1, 2, 3, 4, 5];
  return (
    <div
      className={`relative h-${isMobile ? 16 : 32}`}
      style={{ width: isMobile ? "256px" : "512px" }}
    >
      {images.map((image, i) => (
        <img
          src={`/team${i + 1}.png`}
          alt="temporary alt"
          // style={{ borderColor: isMobile ? "#30C0B8" : "#4673E0" }}
          className={`absolute w-${isMobile ? 16 : 32} h-${
            isMobile ? 16 : 32
          } rounded-full top-0 left-${i * (isMobile ? 12 : 24)} z-${
            50 - i * 10
          }`}
        />
      ))}
    </div>
  );
};

const Investor = ({ investor }) => {
  const { name, src } = investor;

  return (
    <div className="text-center">
      <img
        src={src}
        alt={name}
        style={{ border: "1px solid black" }}
        className="rounded-full w-16 md:w-24 h-16 md:h-24 mx-auto"
      />
      <p className="text-xs md:text-base mt-2.5">
        {name.split(" ")[0]} <br className="md:hidden" />
        {name.split(" ")[1]}
      </p>
    </div>
  );
};

const Investors = () => {
  return (
    <div className="p-4 sm:p-12 max-w-screen-xl mx-auto mt-12 sm:mt-20 mb-12 flex justify-between flex-col md:flex-row">
      <div className="md:w-5/12">
        <p className="font-normal text-2xl md:text-5xl mt-2">
          We are backed by leading investors
        </p>
        <p className="text-sm md:text-xl mt-4 md:mt-8 font-normal">
          Our vision for the creator economy is shared by leading investors
          globally. Our investors include 20+ unicorn founders, social platform
          leaders as well as highly reputed institutions around the world.
        </p>
      </div>
      <div className="mt-4 md:m-0">
        <div className="flex">
          <img
            src="/krafton.png"
            alt="krafton"
            className="w-28 md:w-60 md:ml-4 object-contain"
          />
          <img
            src="/peercapital.png"
            alt="peercapital"
            className="w-44 md:w-80 ml-16 md:ml-8 object-contain"
          />
        </div>
        <div className="mt-7 w-full justify-between items-center grid grid-rows-1 grid-cols-4 md:grid-rows-2 gap-x-4 gap-y-4">
          {INVESTORS.map((investor) => (
            <Investor investor={investor} />
          ))}
        </div>
      </div>
    </div>
  );
};

const Discover = () => {
  return (
    <div className="relative">
      <div className="gradient-green">
        <img src="gradient-4.png" alt="onimpression" />
      </div>
      <div
        id="creator-discovery"
        className="campaign-section max-w-screen-xl items-center px-5 mx-auto mt-28 grid grid-cols-1 sm:grid-cols-2 sm:gap-2"
      >
        <div className="flex flex-col creators sm:pl-12">
          <div className="campaign sm:mt-0 mt-10 sm:hidden">
            <img src="campaign-img.png" alt="campaign" />
          </div>
          <div className="text-2xl sm:text-5xl sm:pr-10 font-sans font-bold p-1 mx-auto sm:mr-0 sm:ml-0">
            Discover & access relevant creators instantly
          </div>
          <div className="flex items-center mt-5">
            <CheckGreenIcon />{" "}
            <span className="ml-4 sm:ml-3.5 text-sm sm:text-xl">
              7 Million+ creators
            </span>
          </div>
          <div className="flex items-center mt-2.5">
            <CheckGreenIcon />
            <span className="ml-4 sm:ml-3.5 text-sm sm:text-xl">
              22 Global regions
            </span>
          </div>
          <div className="flex items-center mt-2.5">
            <CheckGreenIcon />
            <span className="ml-4 sm:ml-3.5 text-sm sm:text-xl">
              20+ Powerful filters
            </span>
          </div>
          <div className="flex items-center mt-2.5">
            <CheckGreenIcon />
            <span className="ml-4 sm:ml-3.5 text-sm sm:text-xl">
              Celebrity to Micro creators
            </span>
          </div>
          <div className="flex items-center mt-2.5 text-sm sm:text-xl">
            <CheckGreenIcon />
            <span className="ml-4 sm:ml-3.5">10+ Languages supported</span>
          </div>
        </div>
        <div className="campaign sm:mt-0 mt-8 hidden sm:block">
          <img src="campaign-img.png" alt="campaign" />
        </div>
      </div>
    </div>
  );
};

const Premium = () => {
  return (
    <div
      id="creator-insights"
      className="age-section sm:mt-40 mt-14 max-w-screen-xl px-5 mx-auto grid grid-cols-1 sm:grid-cols-2 sm:gap-2"
    >
      <div className="pl-3">
        <img src="metric.png" alt="oneimpression" />
      </div>
      <div className="flex flex-col md:mt-5 mb-14 sm:text-left creators sm:pl-24 sm:mt-0 mt-8">
        <div className="text-2xl flex flex-col sm:text-5xl sm:pr-10 font-sans font-bold p-1 sm:mr-0 sm:ml-0">
          <div>
            Get premium data with{" "}
            <span className="sm:inline hidden">creator</span>
          </div>
          <div className="flex items-center">
            <div>
              <span className="sm:hidden visible">Creator </span>insights
            </div>
            <img
              src="increase-graph.svg"
              alt="increase graph"
              className="sm:w-20 sm:h-8 h-4 w-8 ml-1 sm:ml-0"
            />
          </div>
        </div>
        <div className="flex items-center mt-5">
          <CheckGreenIcon />
          <span className="ml-4 sm:ml-3.5 text-sm sm:text-xl">
            Performance Data
          </span>
        </div>
        <div className="flex items-center mt-2.5">
          <CheckGreenIcon />
          <span className="ml-4 sm:ml-3.5 text-sm sm:text-xl">
            Audience Demographics
          </span>
        </div>
        <div className="flex items-center mt-2.5">
          <CheckGreenIcon />
          <span className="ml-4 sm:ml-3.5 text-sm sm:text-xl">
            Historical Rating
          </span>
        </div>
      </div>
    </div>
  );
};

const CompetitivePricing = () => {
  return (
    <div className="relative">
      <div
        id="competitive-pricing"
        className="campaign-section max-w-screen-xl items-center px-5 mx-auto sm:mt-28 grid grid-cols-1 sm:grid-cols-2 sm:gap-x-28"
      >
        <div className="campaign sm:mt-0 sm:hidden">
          <img src="competitive.png" alt="campaign" />
        </div>
        <div className="flex flex-col creators sm:pl-12">
          <div className="text-2xl sm:text-5xl sm:pr-10 font-sans font-bold p-1 mx-auto sm:mr-0 sm:ml-0">
            Competitive pricing at lightning fast speed⚡️
          </div>
          <div className="flex font-sans sm:font-normal text-sm sm:text-xl items-center mt-5">
            One Impression platform uses innovative technology, scale, data and
            exclusive partnerships to get you the best deals on top creators.
            Our platform is suitable for businesses of all sizes and our pricing
            model allows us to offer unbeatable prices for every budget.
          </div>
        </div>
        <div className="campaign sm:mt-0 mt-8 hidden sm:block">
          <img src="competitive.png" alt="campaign" />
        </div>
      </div>
    </div>
  );
};

const BRAND_ICONS = [
  { name: "amazon", path: "/brands/amazon.png" },
  { name: "unilever", path: "/brands/unilever.png" },
  { name: "tencent", path: "/brands/tencent.png" },
  { name: "panasonic", path: "/brands/panasonic.png" },
  { name: "mars", path: "/brands/mars.png" },
  { name: "meesho", path: "/brands/meesho.png" },
  { name: "mamaearth", path: "/brands/mamaearth.png" },
  { name: "pharmeasy", path: "/brands/pharmeasy.png" },
  { name: "zomato", path: "/brands/zomato.png" },
  { name: "plum", path: "/brands/plum.png" },
  { name: "myntra", path: "/brands/myntra.png" },
  { name: "lazypay", path: "/brands/lazypay.png" },
  { name: "cetaphil", path: "/brands/cetaphil.png" },
  { name: "pinterest", path: "/brands/pinterest.png" },
];

export const Home = () => {
  const [activeCaseStudy, setActiveCaseStudy] = useState(() => caseStudies[0]);
  const [isScheduleDemoOpen, setIsScheduleDemoOpen] = useState(false);
  const switchCaseStudy = (id) => {
    let active = caseStudies.find((each) => each.id === id);
    setActiveCaseStudy(active);
  };

  const handleWhatsappClickEvent = () => {
    Event("HOMEPAGE TOP SECTION", "whatsapp_button_clicked", "HOMEPAGE");
  };

  return (
    <MainLayout>
      <div className="gradient-blue">
        <img src="gradient-1.png" alt="onimpression" />
      </div>
      <div className="flex flex-col justify-center items-center mt-12 sm:mt-24 mx-auto sm:text-center px-5">
        <h1 className="font-sans text-3xl sm:text-7xl font-bold xl:w-2/3 sm:h-152 text-gradient bg-gradient-to-r from-oi-teal-300 to-oi-purple-300 tracking-wider">
          Start your influencer campaign today
        </h1>
        <p className="max-w-1-xl font-sans text-base sm:text-xl font-normal mt-4 sm:mt-7">
          One Impression helps you seamlessly activate and scale your Influencer
          Marketing Campaigns all in one place
        </p>
        <div className="gird grid-rows-2 grid-flow-col gap-4 mt-12 sm:flex w-full sm:justify-center sm:items-center">
          <div className="items-center justify-center border-2 sm:border-0">
            <Button
              type="white"
              text="Schedule Demo"
              className="sm:text-base text-xs sm:py-.625 sm:mx-1 py-0.5 px-1 w-full"
              onClick={() => setIsScheduleDemoOpen(!isScheduleDemoOpen)}
            ></Button>
          </div>
          <div className="items-center justify-center mt-2 sm:mt-0">
            <a
              href="https://wa.me/919311124148"
              target="_blank"
              rel="noreferrer"
              onClick={() => handleWhatsappClickEvent()}
            >
              <Button
                type="green"
                text="Message on Whatsapp"
                className="sm:text-base text-xs sm:py-.625 sm:mx-1 py-0.5 px-1 w-full"
                rightIcon={
                  <WhatsappIcon className="p-0 ml-1 h-3 w-3 sm:ml-4 sm:h-6 sm:w-6" />
                }
              ></Button>
            </a>
          </div>
        </div>
        <ScheduleDemo
          isOpen={isScheduleDemoOpen}
          setOpen={() => setIsScheduleDemoOpen(!isScheduleDemoOpen)}
        />
      </div>

      <BrandLogos brandIconArray={BRAND_ICONS} />

      <KPISection />

      <div className="rsvp mt-14 sm:mt-44 max-w-screen-xl mx-auto relative flex justify-end px-5">
        <img
          style={{ maxHeight: 689 }}
          src="global-influencer.png"
          alt="rsvp-mage"
        />
      </div>

      <div className="flex items-center flex-col">
        <div className="sm:mt-44 sm:text-7xl text-4xl mt-20 font-sans font-bold ">
          7 Million+ Creators
        </div>
        <div className="sm:mt-8 mt-9 max-w-screen-xl mx-auto relative flex justify-end">
          <img
            src="seven-million.png"
            alt="seven million creators"
            className="hidden sm:block"
          />
          <img
            src="zoomed-seven-million.png"
            alt="seven million creators"
            className="block sm:hidden"
          />
        </div>
      </div>

      <Discover />
      <Premium />
      <CompetitivePricing />

      <div
        id="creator-insights"
        className="age-section sm:mt-28 mt-14 max-w-screen-xl px-5 mx-auto grid grid-cols-1 sm:grid-cols-2 sm:gap-2"
      >
        <div className="pl-3">
          <img src="fully_compliant.png" alt="fully compliant" />
        </div>
        <div className="flex flex-col md:mt-4 sm:mb-14 sm:text-left creators sm:pl-24 sm:mt-0 mt-10">
          <div className="flex relative">
            <div className="text-2xl sm:text-5xl sm:pr-10 font-sans font-bold p-1 mx-auto sm:mr-0 sm:ml-0">
              Fully compliant creator contracts
            </div>
          </div>
          <div className="flex font-sans sm:font-normal text-sm sm:text-xl items-center mt-5">
            Say goodbye to lengthy negotiations and hours spent on legal
            paperwork. With our Fully Compliant Creator Contracts, you can focus
            on what matters most — connecting with the right influencers and
            growing your business.
          </div>
        </div>
      </div>

      <div className="relative">
        <div
          id="creator-access"
          className="campaign-section max-w-screen-xl items-center px-5 mx-auto sm:mt-28 mt-14 grid grid-cols-1 sm:grid-cols-2 sm:gap-x-28"
        >
          <div className="campaign sm:mt-0 sm:hidden">
            <img src="advance_creator.png" alt="campaign" />
          </div>
          <div className="flex flex-col creators sm:pl-12 sm:mb-14">
            <div className="text-2xl sm:text-5xl sm:pr-10 font-sans font-bold p-1 mx-auto sm:mr-0 sm:ml-0">
              Advanced creator briefing system
            </div>
            <div className="flex font-sans sm:font-normal text-sm sm:text-xl items-center mt-5">
              Make content creation faster, easier, and more efficient with our
              briefing system! No more manual work or worries about errors. Take
              advantage of this advance system to ensure that your briefs are
              accurate and perfect every time.
            </div>
          </div>
          <div className="campaign sm:mt-0 mt-8 hidden sm:block">
            <img src="advance_creator.png" alt="campaign" />
          </div>
        </div>
      </div>

      <div id="real-time-tracking" className="relative">
        <div className="gradient-purple">
          <img src="gradient-5.png" alt="oneimpression" />
        </div>
        <div className="max-w-screen-xl px-5 mx-auto sm:mt-28 mt-14 grid grid-cols-1 sm:grid-cols-2 sm:gap-3">
          <div className="flex items-center justify-center performance-img sm:mt-0 mt-10">
            <img src="performance.png" alt="relationshipimage" />
          </div>
          <div className="flex flex-col sm:pl-12 sm:text-left md:mt-4">
            <div className="text-2xl sm:text-5xl sm:pr-10 font-sans font-bold p-1 mx-auto sm:mr-0 sm:ml-0 md:w-5/6">
              Real time performance tracking & analytics
            </div>
            <div className="font-sans sm:font-normal text-sm sm:text-xl p-1 mx-auto sm:mr-0 sm:ml-0">
              Track and analyse your campaign performance in real-time. Get deep
              insights at every level, from creator, content, category, gender
              and platform. You will exercise unparalleled control over your
              campaigns, all on a single platform.
            </div>
          </div>
        </div>
      </div>

      <div
        id="one-ads"
        className="max-w-screen-xl px-5 mx-auto sm:mt-28 mt-14 grid grid-cols-1 sm:grid-cols-2 sm:gap-3"
      >
        <div className="flex items-center min-w-full sm:pl-12 justify-center ads-img sm:ml-0 mx-auto sm:hidden">
          <img
            src="Ads.png"
            className="h-100 min-w-full object-cover "
            alt="relationshipimage"
          />
        </div>
        <div className="flex flex-col md:mt-5 sm:pl-8 sm:mt-0">
          <div className="flex">
            <span className="mb-2 ml-1 bg-oi-orange-400 py-.5 px-2.5 text-oi-white-300 rounded-3xl">
              New
            </span>
          </div>
          <div className="text-2xl sm:text-5xl font-sans  sm:text-left font-bold p-1 sm:mx-auto sm:mr-0 sm:ml-0 md:w-5/6">
            Multiply ROI via One Ads
          </div>
          <div className="font-sans sm:font-normal text-sm sm:text-xl p-1 mx-auto sm:mr-0 sm:ml-0">
            <p>
              Leverage the power of organic content created by creators to drive
              action by turning into paid social ads. With a strong record for
              improved performance, this magic tool will help you significantly
              improve your ROI.
            </p>
          </div>
        </div>
        <div className="flex items-center min-w-full sm:pl-12 justify-center ads-img sm:ml-0 mx-auto hidden sm:block">
          <img
            src="Ads.png"
            className="h-100 min-w-full object-cover "
            alt="relationshipimage"
          />
        </div>
      </div>

      <div className="relative">
        <div className="gradient-parrot">
          <img src="gradient-6.png" alt="onimpression" />
        </div>
        <div className="max-w-screen-lg px-5 items-center mx-auto mt-16">
          <h1 className="font-normal mx-auto text-2xl sm:text-center sm:text-5xl sm:leading-snug">
            Stay organised.
            <br />
            You can take a break from the following
          </h1>
          <div className="mt-6 sm:mt-12 grid gap-y-6 sm:gap-y-12 grid-cols-2 sm:grid-cols-3 sm:text-center">
            <div>
              <span className="relative text-base sm:text-3xl">
                Manual Searches
                <div className="absolute bg-oi-teal-400 h-0.5 left-0 w-full top-1/2"></div>
              </span>
            </div>
            <div>
              <span className="relative text-base sm:text-3xl">
                Influencer Outreach
                <div className="absolute bg-oi-teal-400 h-0.5 left-0 w-full top-1/2"></div>
              </span>
            </div>
            <div>
              <span className="relative text-base sm:text-3xl">
                Price Negotiations
                <div className="absolute bg-oi-teal-400 h-0.5 left-0 w-full top-1/2"></div>
              </span>
            </div>
            <div>
              <span className="relative text-base sm:text-3xl">
                Blind Decisions
                <div className="absolute bg-oi-teal-400 h-0.5 left-0 w-full top-1/2"></div>
              </span>
            </div>
            <div>
              <span className="relative text-base sm:text-3xl">
                Painful Contracts
                <div className="absolute bg-oi-teal-400 h-0.5 left-0 w-full top-1/2"></div>
              </span>
            </div>
            <div>
              <span className="relative text-base sm:text-3xl">
                Payment Hassles
                <div className="absolute bg-oi-teal-400 h-0.5 left-0 w-full top-1/2"></div>
              </span>
            </div>
            <div>
              <span className="relative text-base sm:text-3xl">
                Delivery Issues
                <div className="absolute bg-oi-teal-400 h-0.5 left-0 w-full top-1/2"></div>
              </span>
            </div>
            <div>
              <span className="relative text-base sm:text-3xl">
                Compiling Reports
                <div className="absolute bg-oi-teal-400 h-0.5 left-0 w-full top-1/2"></div>
              </span>
            </div>
            <div>
              <span className="relative text-base sm:text-3xl">
                Multiple Agencies
                <div className="absolute bg-oi-teal-400 h-0.5 left-0 w-full top-1/2"></div>
              </span>
            </div>
          </div>
        </div>
      </div>

      <Investors />

      <div className="max-w-screen-xl px-5 mx-auto mt-12 hidden">
        <div className="flex flex-col items-center  ">
          <div className="case-studies-heading font-sans">Our Case Studies</div>
          <div className="case-studies-description mx-auto text-center">
            Helping brands succeed and drive measurable results
          </div>
        </div>

        <div className="grid lg:grid-cols-5 md:grid-cols-4 grid-cols-2  gap-2">
          <div
            className="case-brands flex items-center justify-center cursor-pointer"
            onClick={() => switchCaseStudy(1)}
          >
            <img src="unilever.png" alt="oneimpression" />
          </div>
          <div
            className="case-brands flex items-center justify-center  cursor-pointer"
            onClick={() => switchCaseStudy(2)}
          >
            <img src="mamaearth.png" alt="oneimpression" />
          </div>
          <div
            className="case-brands flex items-center justify-center  cursor-pointer"
            onClick={() => switchCaseStudy(3)}
          >
            <img src="meesho.png" alt="oneimpression" />
          </div>
          <div
            className="case-brands flex items-center justify-center  cursor-pointer"
            onClick={() => switchCaseStudy(4)}
          >
            <img src="pubg.png" alt="oneimpression" />
          </div>
          <div
            className="case-brands flex items-center justify-center  cursor-pointer"
            onClick={() => switchCaseStudy(5)}
          >
            <img src="insta.png" alt="oneimpression" />
          </div>
        </div>

        <div className="mt-20 grid grid-cols-1 lg:grid-cols-2 sm:gap-3">
          <div>
            <img
              src={activeCaseStudy.img}
              className="lg:ml-0 mx-auto"
              alt="oneimpression"
            />
          </div>
          <div className="flex flex-col mt-12 sm:pl-16">
            <div className="flex items-end">
              <div className="flex flex-col">
                <h2 className="leading-heading font-normal sm:text-4xl text-2xl mb-3">
                  {activeCaseStudy.data.brand}
                </h2>
                <div>
                  <div className="flex items-center">
                    <h2 className="text-oi-blue-400  font-bold sm:text-7xl text-5xl leading-heading">
                      24%
                    </h2>
                    <svg
                      className="ml-5"
                      width="32"
                      height="41"
                      viewBox="0 0 32 41"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M31.5 15.8917L16 0.5L0.5 15.8917L3.23802 18.6106L14.0973 7.87327L14.0973 40.5H17.9027L17.9027 7.87327L28.762 18.6106L31.5 15.8917Z"
                        fill="#1D252D"
                      />
                    </svg>
                  </div>
                  <h4 className="font-bold mt-2">Engagement</h4>
                </div>
              </div>
              <div className="flex flex-col sm:ml-17 ml-10">
                <div>
                  <div className="flex items-center">
                    <h2 className="text-oi-blue-400 font-bold sm:text-7xl text-5xl leading-heading">
                      5x
                    </h2>
                    <svg
                      className="ml-5"
                      width="32"
                      height="41"
                      viewBox="0 0 32 41"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M31.5 15.8917L16 0.5L0.5 15.8917L3.23802 18.6106L14.0973 7.87327L14.0973 40.5H17.9027L17.9027 7.87327L28.762 18.6106L31.5 15.8917Z"
                        fill="#1D252D"
                      />
                    </svg>
                  </div>
                  <h4 className="font-bold mt-2">CTRs</h4>
                </div>
              </div>
            </div>
            <div className="flex items-start sm:-ml-16  mt-12">
              <svg
                width="45"
                height="34"
                viewBox="0 0 45 34"
                fill="none"
                style={{ minWidth: "43" }}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M17.7182 26.4953V33.0653C6.2329 32.2867 0.490234 26.398 0.490234 15.3993V0.361328H17.7182V17.5893H10.7102C10.7102 22.6507 13.0462 25.6193 17.7182 26.4953ZM44.1442 26.4953V33.0653C32.6589 32.2867 26.9162 26.398 26.9162 15.3993V0.361328H44.1442V17.5893H37.1362C37.1362 22.6507 39.4722 25.6193 44.1442 26.4953Z"
                  fill="#30B8C0"
                />
              </svg>
              <p className="text-xl leading-7 pl-5">
                We launched a campaign in under 10 minutes and got to choose
                from such an authentic roster of creators that were the perfect
                fit for our brand We launched a campaign in under 10 minutes and
                got to choose
              </p>
            </div>
            <div className="mt-8 flex items-center">
              <img src="user.png" alt="oneimpression" />
              <div className="ml-6">
                <span className="font-bold text-lg">Sambit Dash</span>
                <p className="text-base">VP Marketing, mamaearth</p>
              </div>
            </div>
            <div className="flex items-center w-full mt-12">
              <Button className="sm:w-60">View Case Study</Button>
              <Button type="outline" className="sm:w-60 ml-10">
                Talk to us
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-12 px-5 max-w-screen-xl text-center mx-auto relative">
        <div className="gradient-green sm:hidden">
          <img src="gradient-6.png" alt="onimpression" />
        </div>
        <h2 className="text-2xl sm:text-5xl mb-3 md:mb-4 font-bold">
          We’ve been featured In
        </h2>
        <div className="mt-4 md:mt-12">
          <div className="lg:flex w-full justify-between items-center grid grid-rows-1 lg:grid-cols-6 sm:grid-cols-3 grid-cols-4 gap-x-4">
            {/* <a
              href="https://qz.com/india/1720880/instagram-youtube-tiktok-stars-are-clicking-with-indian-brands/"
              target="_blank"
              rel="noreferrer"
            > */}
            <div className="flex items-center justify-center">
              <img
                style={{ width: 117, height: 19 }}
                className="object-contain"
                src="/news/quartz.png"
                alt="onimpression"
              />
            </div>
            {/* </a>
            <a href="/" target="_blank" rel="noreferrer"> */}
            <div className="flex items-center justify-center">
              <img
                style={{ width: 86, height: 27 }}
                className="object-contain"
                src="/news/moneycontrol.png"
                alt="onimpression"
              />
            </div>
            {/* </a>
            <a href="/" target="_blank" rel="noreferrer"> */}
            <div className="flex items-center justify-center">
              <img
                style={{ width: 86, height: 27 }}
                src="/news/outlook.png"
                className="object-contain"
                alt="onimpression"
              />
            </div>
            {/* </a>
            <a
              href="https://www.businessinsider.in/advertising/ad-agencies/article/one-impression-eyes-to-grow-by-10x-this-year-after-becoming-a-3-million-platform-in-2019/articleshow/74219649.cms"
              target="_blank"
              rel="noreferrer"
            > */}
            <div className="flex items-center justify-center">
              <img
                style={{ width: 78, height: 42 }}
                className="object-contain"
                src="/news/business-insider.png"
                alt="onimpression"
              />
            </div>
            {/* </a>
            <a
              href="https://www.forbesindia.com/article/south-celebrity-special-2021/beyond-language-barriers-meet-the-social-media-influencers-of-the-south/71123/1"
              target="_blank"
              rel="noreferrer"
            > */}
            <div className="flex items-center justify-center">
              <img
                src="/news/forbes.png"
                style={{ width: 124, height: 34 }}
                className="object-contain"
                alt="onimpression"
              />
            </div>
            {/* </a>
            <a href="/" target="_blank" rel="noreferrer"> */}
            <div className="flex items-center justify-center">
              <img
                style={{ width: 213, height: 28 }}
                className="object-contain"
                src="/news/economic-times.png"
                alt="onimpression"
              />
            </div>
            {/* </a>
            <a
              href="https://qz.com/india/1720880/instagram-youtube-tiktok-stars-are-clicking-with-indian-brands/"
              target="_blank"
              rel="noreferrer"
            > */}
            <div className="flex items-center justify-center">
              <img
                style={{ width: 91, height: 33 }}
                className="object-contain"
                src="/news/mint.png"
                alt="onimpression"
              />
            </div>
            {/* </a>
            <a href="/" target="_blank" rel="noreferrer"> */}
            <div className="flex items-center justify-center">
              <img
                style={{ width: 101, height: 43 }}
                className="object-contain"
                src="/news/the-hindu.png"
                alt="onimpression"
              />
            </div>
            {/* </a> */}
          </div>
        </div>
      </div>
      {/* <div className="relative">
				<div className="gradient-investor">
          <img src="gradient-investor.png" alt="onimpression" />
        </div>
				<div className="pt-40 px-5 max-w-screen-xl text-center mx-auto">
					<h2 className="text-4xl mb-4 font-bold">
						We are backed by leading investors
					</h2>
					<div className="mt-12">
						<div className="grid grid-rows-2 lg:grid-cols-6 md:grid-cols-4 sm:grid-cols-3 grid-cols-2 gap-y-14">
							{INVESTORS.map(({ src, href, name }) => {
								return (
									<div key={src}>
										<ProfileCard src={src} name={name} href={href} />
									</div>
								);
							})}
						</div>
					</div>
				</div>
			</div> */}
      <div
        className="mt-8 sm:mt-20 p-8 md:py-12"
        style={{ background: `linear-gradient(90deg, #30B8C0, #4673E0)` }}
      >
        <div className="max-w-screen-xl mx-auto flex justify-between items-center">
          <div>
            <span className="sm:hidden">
              <Team isMobile />
            </span>
            <p className="text-base sm:text-2xl text-white mt-4 sm:m-0">
              We're hiring <span className="text-base sm:text-3xl">🎉</span>
            </p>
            <h2 className="text-2xl font-bold mt-4 text-white mb-6 sm:text-5xl sm:leading-tight">
              Join our team of
              <br />
              Rockstars
            </h2>
            <a
              href={hiringURL}
              target="_blank"
              rel="noreferrer"
              className="mb-4 inline-block"
            >
              <Button
                style={{ background: "#1d252d" }}
                text="View All Open Roles"
              />
            </a>
          </div>
          <span className="hidden sm:block">
            <Team />
          </span>
        </div>
      </div>

      <div className="gradient-footer">
        <img src="gradient-7.png" alt="onimpression" />
      </div>
    </MainLayout>
  );
};

export default Home;
