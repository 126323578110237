import React from "react";

// COMPONENTS
import { TermsAndConditionsLayout } from "../../../layouts/TermsAndConditions";
import { FontBold } from "../../../components/PrivacyPageComponents";

const postingTerms = [
  {
    serial_no: "a",
    value:
      "The Influencer may not edit a Campaign Deliverable after Brand has approved the Campaign Deliverable.",
  },
  {
    serial_no: "b",
    value:
      "The Influencer is solely responsible for the publication of unapproved content.",
  },
  {
    serial_no: "c",
    value:
      "After a Campaign Deliverable is published, the Influencer will not publish any Post at least for 24 hours where the effect of publishing such additional posts would be to reduce the prominence of the Campaign Deliverable.",
  },
  {
    serial_no: "d",
    value:
      "Brand has the right at any time to moderate any Campaign Deliverable after publication for a valid reason and the Influencer will immediately make any reasonable modification or amendment requested by Brand.",
  },
  {
    serial_no: "e",
    value:
      "In case any issue arises post publishing of content then Brand has the right to request that the Campaign Deliverable will be removed and the Influencer will comply with such a request immediately upon receipt of such notification.",
  },
  {
    serial_no: "f",
    value:
      "In case of any defect or shortcomings in the agreed deliverables, Influencer shall remove such defect or shortcoming post receipt of request from Brand.",
  },
];

const campaignDeliverableTerms = [
  {
    serial_no: "a",
    value: "complies with all applicable laws and regulations.",
  },
  {
    serial_no: "b",
    value:
      "complies with all terms and conditions set by the platform on which the Campaign Deliverable is published by the Influencer.",
  },
  {
    serial_no: "c",
    value:
      "The Influencer agrees to abide by all guidelines for all blog posts, social media statuses, tweets, and/or comments, which should be in good taste and free of inappropriate language and/or content promoting bigotry, racism or discrimination based on race, gender, religion, nationality, disability, sexual orientation, or age and shall not act in a manner which can harm the Brand’s image and reputation.",
  },
  {
    serial_no: "d",
    value:
      "does not contain any content which you know or suspect (or ought reasonably to have known or suspected) to be false, misleading or deceptive.",
  },
  {
    serial_no: "e",
    value:
      "does not contain any content that is indecent, discriminatory, or offensive or contains or promotes aggressive or illegal behaviour or sexually suggestive imagery. Does not violate the privacy rights, contract rights, intellectual property rights or other rights of any person, corporation or entity.",
  },
];

const codeOfConduct = [
  {
    serial_no: "a",
    value:
      "Always disclose your partnership with the brand clearly and conspicuously in every post or content piece related to the campaign. Use hashtags like #ad, #sponsored, #partner, or similar to make it evident.",
  },
  {
    serial_no: "b",
    value:
      "Ensure that all information provided in your content is accurate and factually correct.",
  },
  {
    serial_no: "c",
    value:
      "Avoid making false claims, exaggerations, or misleading statements about the brand's products or services.",
  },
  {
    serial_no: "d",
    value:
      "Maintain professional and respectful communication with the Brand representatives throughout the collaboration.",
  },
  {
    serial_no: "e",
    value:
      "Respond promptly to emails, messages, and inquiries from the Brand.",
  },
  {
    serial_no: "f",
    value:
      "Create high-quality and engaging content that aligns with your personal style while also meeting the brand's guidelines.",
  },
  {
    serial_no: "g",
    value:
      "Adhere to copyright laws and give proper credit if using third-party content.",
  },
  {
    serial_no: "h",
    value:
      "Not misuse any Campaign Deliverables created by the Influencer post completion of the Campaign.",
  },
  {
    serial_no: "i",
    value:
      "Adhere to the agreed-upon deadlines for content creation and submission.",
  },
  {
    serial_no: "j",
    value:
      "Respect the privacy of your audience and avoid sharing sensitive personal information without consent.",
  },
  {
    serial_no: "k",
    value:
      "Comply with data protection laws and guidelines if and when collecting and using user data for Campaigns.",
  },
  {
    serial_no: "l",
    value:
      "Follow the Brand's Campaign guidelines, including visual aesthetics, tone of voice, and any specific messaging they require.",
  },
  {
    serial_no: "m",
    value: "Seek clarification from the brand if any guidelines are unclear.",
  },
  {
    serial_no: "n",
    value:
      "Avoid engaging in any activity that might damage your credibility or that of the brand.",
  },
  {
    serial_no: "o",
    value:
      "Refrain from using dishonest practices such as buying followers, likes, or engagement.",
  },
  {
    serial_no: "p",
    value:
      "Consider building long-term relationships with brands based on mutual trust and successful collaborations.",
  },
  {
    serial_no: "q",
    value:
      "Demonstrate reliability and professionalism to enhance your reputation as a trustworthy influencer.",
  },
];

const additionalTCforOneClub = [
  {
    serial_no: "a",
    value: (
      <>
        <div>
          About OneClub: Through OneClub we are building an ecosystem for the
          Influencers leveraging technology and tools to provide them end to end
          solutions in their overall content creation journey, where we would be
          exclusively managing them and their business. OneClub services offer
          the following additional services to the Influencer:
        </div>
        <ul className="list-disc ml-4">
          <li>
            From comprehensive CRM to dedicated talent manager to data-driven
            insights and financial management, we are providing the creators
            with the resources to make informed decisions and streamline every
            aspect of their journey.
          </li>
          <li>
            Various growth tools such as partnerships across various verticals,
            access to Elite exclusive communities, etc.
          </li>
          <li>
            Access to a diverse range of income streams, including brand
            partnerships, sponsorships, affiliate marketing, online and offline
            events, and digital product sales.
          </li>
        </ul>
      </>
    ),
  },
  {
    serial_no: "b",
    value:
      "The Influencer has represented and assured to the Company that he/ she has the requisite competence, expertise and talent and has approached the Company to perform, be associated and be promoted exclusively by the Company, as an Artist for influencer marketing, managing brands for the Artist, end to end, modelling in advertisements and/or commercials, video album, personal appearances, endorsement of products, or any other assignment related to the TV, internet, telecommunication networks or any electronic, print media (including but not limited to social media and other similar media whether now known or hereafter introduced or invented or developed) produced by the Company under its own banner and/or by other third parties.",
  },
  {
    serial_no: "c",
    value:
      "In consideration of the exclusivity of the association being offered by the Influencer to the Company and the representations by the Influencer that he/ she has the requisite competence, expertise and talent, the Company has agreed to handle the complete role of artist management and promotion of the Influencer in the manner and on terms and conditions hereinafter mentioned.",
  },
  {
    serial_no: "d",
    value:
      "The OneClub feature may be availed by paying a separate fee as per the pricing plans set out on the Platform or as otherwise agreed between the Company and the Influencer.",
  },
  {
    serial_no: "e",
    value: (
      <>
        <div>
          The Influencer has approached the Company to perform, be associated
          and promoted exclusively by the Company, and to render his/her
          services for the Assignments procured by the Company and produced by
          the Company under its own banner and/or by other third parties and the
          Company has agreed to handle the complete role of artist management on
          terms and conditions set out herein. It is expressly provided that
          during the Term all assignments, projects undertaken by the Influencer
          shall exclusively be negotiated and handled by the Company. Any
          Assignment procured by the Company will be disclosed to the Company.
        </div>
        <div className="mt-2">
          For the purpose of these Terms, "Assignment" shall mean the work
          assignments and deals procured by the Company or by the Influencer for
          the Influencer to render their services.
        </div>
      </>
    ),
  },
  {
    serial_no: "f",
    value:
      "The Company shall have the exclusive right to promote the Influencer and procure Assignments and handle the complete role of artist management and promotion for the Influencer.",
  },
  {
    serial_no: "g",
    value:
      "The Company shall have the right to receive the revenues on behalf of the Influencer for the Assignments procured by the Company and also for Assignments procured by the Influencer and shall make the payments to the Influencer as mutually agreed.",
  },
  {
    serial_no: "h",
    value:
      "The Company shall have the right to use the name of the Influencer or any name by which the Influencer has acquired, public repute and goodwill, if any, the photographs or other likeness of the Influencer, to write and to publish and permit others to write and publish articles concerning the Influencer for advertising and publicity matter concerned with the Assignment(s) and the Influencer shall not object to any such exploitation.",
  },
  {
    serial_no: "i",
    value:
      "The Influencer hereby agrees and undertakes that he/ she shall take on Assignments only on the terms and conditions consented and agreed upon by the Company and agrees to complete all the assignments as per the contracts of each Assignment. The Influencer agrees and confirms to accept as many Assignments as the Company wishes him/ her to do.",
  },
  {
    serial_no: "j",
    value:
      "The Influencer agrees and undertakes that he/ she shall not accept any Assignments during the Term of this Agreement without the prior written consent and approval of the Company and shall accept such assignments on the remunerations as approved by the Company. Further, such Assignments shall be handled only by the Company and the Company shall be entitled to its share of revenue in respect of the same.",
  },
  {
    serial_no: "k",
    value:
      "The Influencer agrees and undertakes that he/ she shall devote his/ her entire time for all the Assignments and shall attend all the meetings, discussions, shootings, re-shootings, dubbing, rehearsals, photo sessions, or any other sessions concerning the Assignments or any parts thereof as and when required by the Company and as per the schedules decided by the Company. The Influencer agrees and undertakes that he/ she shall make himself/ herself available for publicity which entails press conferences and/ or releases, television and/ or any tour as and when required by the Company.",
  },
  {
    serial_no: "l",
    value:
      "The Influencer agrees that it will not make any public statements with respect to any Assignments without the prior approval of the Company.",
  },
  {
    serial_no: "m",
    value:
      "The Influencer has not and shall not, directly or indirectly, whether by himself/ herself or jointly with another or through any of the Influencer’s agents, enter(ed) into any agreement or arrangements which may in the Company's opinion prejudice and/or conflict with the rights of the Company under these Terms and Conditions.",
  },
  {
    serial_no: "n",
    value:
      "If commercially agreed, the ownership of any Campaign Deliverables created by the Influencer will be passed on to the Brand under whose Campaign such Campaign Deliverables are created.",
  },
  {
    serial_no: "o",
    value:
      "The Influencer shall promptly provide and sign any other documents as may be considered necessary by the Company to effectively carry out provision of these Terms and Conditions.",
  },
  {
    serial_no: "p",
    value:
      "The Company shall not be responsible in any way for any of the Influencer’s unfulfilled obligations and/or liabilities or any of its associates, agents, affiliates etc towards any person, party, company, organisation in connection with the Assignments including finance, employment of other contractual and non-contractual agreements/arrangements of whatsoever nature.",
  },
  {
    serial_no: "q",
    value:
      "The Influencer will complete all of its Assignment(s) undertaken by it even after expiry of the engagement between the Company and the Influencer without claiming any additional revenue from the Company.",
  },
  {
    serial_no: "r",
    value:
      "The Influencer shall indemnify and keep indemnified the Company and hold the Company and its officers, and directors and its parent, subsidiaries and affiliates, harmless from any and all losses, claims, damages, liabilities, reasonable costs and expenses, including reasonable counsel fees, incurred by the Company, arising out of misrepresentation, fraud, gross negligence, or wilful misconduct of, or from the breach of any provision of these Terms and Conditions or any Assignment by the Influencer, of matters relating to either party and the transactions contemplated by them. The Company shall promptly notify the Influencer of any claim or litigation to which the indemnity set forth herein applies.",
  },
  {
    serial_no: "s",
    value:
      "The Influencer agrees and understands that the engagement for talent management under these Terms and Conditions is an exclusive arrangement and the Influencer shall not simultaneously engage any other third-parties entity to undertake same or similar services for the Influencer during the subsistence of the Company’s engagement under these Terms and Conditions.",
  },
  {
    serial_no: "t",
    value:
      "In the event of violation of the exclusivity restriction under these Terms and Conditions and/or if the Influencer commits to any assignment that does not involve the Company, the Influencer will pay the Company a penalty equivalent to 10 times the value of such external assignment as a compensation for lost revenue. The Influencer agrees and understands that the liquidated damages contemplated hereunder are legitimate and fair.",
  },
];

export const InfluencerTermsAndCondition = () => {
  return (
    <TermsAndConditionsLayout>
      <div className="mx-auto mt-8 lg:mt-16 px-5 max-w-lg sm:max-w-screen-sm md:max-w-screen-md lg:max-w-screen-xl">
        <div className="text-2xl not-italic text-center font-bold font-serif text-oi-gray-900 tracking-wider mb-5">
          INFLUENCER TERMS AND CONDITIONS
        </div>
        <div className="mb-5 flex justify-center">
          <div className="text-xs flex flex-col w-4/5 not-italic font-normal font-serif text-black mb-2">
            <div>
              As an Influencer, you are agreeing to the following terms and the
              Terms and Conditions, which are incorporated in your work order by
              reference.
            </div>
            <div className="mt-5">
              <div>
                i. <FontBold text="Posting Terms:" />
              </div>
              <div className="flex flex-row">
                <div className="ml-5">
                  {postingTerms.map(({ serial_no, value }, index) => (
                    <div
                      className="flex flex-row mt-5"
                      key={`${index}_${serial_no}_postingTerms`}
                    >
                      <div>{serial_no}.</div>
                      <div className="ml-5">{value}</div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="mt-5">
              <div>
                ii. <FontBold text="Campaign Deliverable Terms:" />
              </div>
              <div>
                The Influencer represents and warrants that each Campaign
                Deliverable:
              </div>
              <div className="flex flex-row">
                <div className="ml-5">
                  {campaignDeliverableTerms.map(
                    ({ serial_no, value }, index) => (
                      <div
                        className="flex flex-row mt-5"
                        key={`${index}_${serial_no}_deliverable`}
                      >
                        <div>{serial_no}.</div>
                        <div className="ml-5">{value}</div>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
            <div className="mt-5 flex flex-row">
              <div>iii.</div>
              <div>
                The Influencer shall not, during this engagement or at any time
                in the future, directly or indirectly make any critical or
                disparaging statements to any third parties (including, without
                limitation, any print or broadcast media) about the Company,
                Brand, Brand affiliates, Campaigns or any of their Campaign
                Subject Matter, employees, or clients.
              </div>
            </div>
            <div className="mt-5 flex flex-row">
              <div>iv.</div>
              <div>
                Influencer agrees that: (i) Brand and Company may use influencer
                name, likeness, and social profile links and/or handles in
                campaign-related correspondence and post-campaign case studies;
                (ii) all information related to the services that Brand or the
                Company provide or any information, materials or content are
                confidential information and Influencer shall agree to protect
                and not disclose them to any third party or use them for his/her
                personal purpose. Upon termination of this engagement or upon
                the request of the Brand/Company, the Influencer will return to
                the Company/Brand all of the confidential information, and all
                copies or reproductions thereof, which are in Influencer's
                possession or control; (iii) all content in the Campaign
                Deliverable shall be the original work of influencer and such
                work will not infringe any third party's rights when providing
                these services; (iv) no employment or similar relationship
                exists between Influencer and Brand/ Company. (vii) Influencer
                shall perform the Campaign on the performance date and deliver
                the Campaign Deliverables within the delivery date on the work
                order/confirmation email. (viii) Influencer shall refund the
                paid amount in case of non-performance or partial performance of
                services in the Campaign.
              </div>
            </div>
            <div className="mt-5 flex flex-row">
              <div>v.</div>
              <div>
                Influencer shall comply with all applicable laws and industry
                self-regulation relating to influencer marketing, including but
                not limited to the Advertising Standard Council of India (ASCI),
                AANA Guidelines (AU), CAP Code (UK), and Federal Trade
                Commission’s Guides Concerning Endorsements and Testimonials in
                Advertising (USA) as applicable to each influencer while
                performing any activity in the Campaign for the respective
                Brand. All influencer marketing posts which are being
                facilitated through Company should be transparent.
              </div>
            </div>
            <div className="mt-5 flex flex-row">
              <div>vi.</div>
              <div>
                The Influencer may not edit a Post after Brand has approved the
                Post. The Influencer is solely responsible for the publication
                of unapproved Campaign Deliverable. After a Campaign Deliverable
                is published, the Influencer will not publish any posts within
                next 24 hours where the effect of publishing such additional
                posts would be to reduce the prominence of the earlier Post.
                Brand has the right at any time to moderate any Campaign
                Deliverable after publication for a valid reason and the
                Influencer will immediately make any reasonable modification or
                amendment requested by Brand. Brand has the right at any time to
                request that the Campaign Deliverable will be removed and the
                Influencer will comply with such a request immediately upon
                receipt of such notification. In case of any defect or
                shortcomings in the agreed Campaign Deliverable, Influencer
                shall remove such defect or shortcoming post receipt of request
                from Brand.
              </div>
            </div>
            <div className="mt-5 flex flex-row">
              <div>vii.</div>
              <div>
                During the promotional activity Brand may provide Campaign
                Subject Matter to the Influencer for creating Campaign
                Deliverables for respective Brand. The Campaign Subject Matter
                will be delivered by the Brand which may be further
                authenticated via One Time Password (OTP) which enables the
                Influencer to accept/ or take delivery of the Campaign Subject
                Matter so provided by the Brand. Influencer shall immediately
                update Brand or the Company that they have received the Campaign
                Subject Matter. In case Influencer does not create the Campaign
                Deliverables post acceptance/receipt of Campaign Subject Matter
                then under such scenario such Influencer’s Account shall be
                removed from the Company’s network and the Influencer shall at
                their own expense return to the Brand the Campaign Subject
                Matter in the same condition that it was delivered to the
                Influencer. In the event that a Campaign Subject Matter is to be
                returned to the Brand post the Campaign and the Influencer does
                not return the Campaign Subject Matter within the mutually
                agreed time period, the Company and the Brand may hold the
                Influencer liable for any damages that may arise from not
                returning the Campaign Subject Matter and they are entitled to
                all remedies available under law including taking civil and
                criminal actions against the Influencer.
              </div>
            </div>
            <div className="mt-5 flex flex-row">
              <div>viii.</div>
              <div>
                In case Influencer is entering into these Terms and Conditions
                through an Agency ("Agency”) then it shall be the duty of the
                Agency to ensure that Influencer shall comply the terms
                mentioned under these Terms and Conditions. If Influencer fails
                to comply the Terms and Conditions, in such case, Agency shall
                be liable for the consequences whether legally or pecuniary or
                both and Agency shall adhere to the terms mentioned under these
                Terms and Conditions, to the extent of its applicability to
                Agency.
              </div>
            </div>
            <div className="mt-5 flex flex-row">
              <div>ix.</div>
              <div>
                Fees and Payments:
                <div className="ml-5 mt-2">
                  As soon as the Campaign is confirmed, the Company shall issue
                  an estimate stating the commercials, payment terms such as
                  days of credit or advance terms etc. and the Brand shall
                  confirm the same over an email, PO or such other means at the
                  Company’s discretion. The payment will be made as per the
                  terms stated in such confirmation email. The Brand shall
                  ensure that payments are made on time without any undue delay.
                </div>
              </div>
            </div>
            <div className="mt-5 flex flex-row">
              <div>x.</div>
              <div>
                Campaign Cancellation: Once a Campaign has been confirmed by the
                Influencer, it cannot be cancelled by the influencer for any reason whatsoever.
              </div>
            </div>
            <div className="mt-5 flex flex-row">
              <div>xi.</div>
              <div>
                The Influencer shall not cancel any date for the purpose of
                meetings, discussions, shooting, re-shootings, extra shootings,
                dubbing, discussions, rehearsal, recordings or re-recordings,
                photo sessions, or any other sessions or any parts thereof etc.
                for the Assignment.
              </div>
            </div>
            <div className="mt-5 flex flex-row">
              <div>xii.</div>
              <div>
                Code of Conduct: The Influencer shall render services in
                consonance with the following guidelines:
                <div className="ml-5 mt-2">
                  {codeOfConduct.map(({ serial_no, value }, index) => (
                    <div
                      className="flex flex-row mt-5"
                      key={`${index}_${serial_no}_codeOfConduct`}
                    >
                      <div>{serial_no}.</div>
                      <div className="ml-5">{value}</div>
                    </div>
                  ))}
                </div>
                <div className="mt-4">
                  By adhering to this code of conduct, influencers can build
                  strong and ethical relationships with brands, maintain the
                  trust of their audience, and contribute positively to the
                  influencer marketing industry.
                </div>
              </div>
            </div>
            <div className="mt-5 flex flex-row">
              <div>xiii.</div>
              <div>
                Additional terms and conditions for OneClub Influencers:
                <div className="ml-5 mt-2">
                  {additionalTCforOneClub.map(({ serial_no, value }, index) => (
                    <div
                      className="flex flex-row mt-5"
                      key={`${index}_${serial_no}_additionTandC`}
                    >
                      <div>{serial_no}.</div>
                      <div className="ml-5">{value}</div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </TermsAndConditionsLayout>
  );
};
