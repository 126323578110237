export const BrandLogos = ({ page, brandIconArray = [] }) => {
	const isGetStarted = page === "get-started";
	return (
		<div className="flex mt-16 sm:mt-20 pb-8 flex-col space-between max-w-screen-xl mx-auto">
			<div
				className={`grid ${
					isGetStarted ? "xl:grid-cols-5" : "xl:grid-cols-7"
				} lg:grid-cols-6 md:grid-cols-4 sm:grid-cols-3 grid-cols-5 w-full items-center ${
					isGetStarted ? "sm:gap-y-12" : "sm:gap-y-17"
				} gap-y-6`}
			>
				{brandIconArray.length > 0 &&
					brandIconArray?.map(({ path, name }) => (
						<img
							className={`mx-auto w-11 h-3.5 object-contain ${
								isGetStarted ? "sm:w-24 sm:h-6" : "sm:mt-2 sm:w-28 sm:h-9"
							}`}
							src={path}
							alt={name}
						/>
					))}
			</div>
		</div>
	);
};
